import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import { Button, TextField } from "@mui/material";
import moment from "moment";

const FilterDates = ({
  inputs,
  setInputs,
  handleSearch,
  handleCloseFilterDrawer,
  EMPTY_FILTER,
}) => {
  const maxDate = moment().format("YYYY-MM-DD");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value) {
    } else {
    }

    const newDateFrom = moment(value, "YYYY-MM-DD")
      .subtract(6, "days")
      .format("YYYY-MM-DD");

    setInputs((values) => ({
      ...values,
      date_from: newDateFrom,
      [name]: value,
    }));
  };

  const handleSearchFilter = (e) => {
    e.preventDefault();
    handleCloseFilterDrawer();
    handleSearch();
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    handleCloseFilterDrawer();
    setInputs(EMPTY_FILTER);
    handleSearch("reset");
  };

  useEffect(() => {
    console.log("inputs __inputs", inputs);
  }, [inputs]);

  return (
    <div className="container">
      <form onSubmit={handleSearchFilter}>
        <div className="row mt-4">
          <div className="col-12">
            <TextField
              sx={{ width: "100%" }}
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              // defaultValue="2020/05/31"
              // inputProps={{ min: "1980/01/01", max: "2020/05/31" }}
              disabled
              name="date_from"
              value={inputs?.date_from}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <TextField
              // required={inputs?.date_from ? true : false}
              required={!inputs?.date_to ? true : false}
              sx={{ width: "100%" }}
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              //   inputProps={{
              //     min: inputs?.date_from,
              //   }}
              // defaultValue="2020/05/31"
              // inputProps={{ min: "1980/01/01", max: "2020/05/31" }}
              inputProps={{
                max: maxDate,
              }}
              name="date_to"
              value={inputs?.date_to}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex justify-content-end gap-3 mt-4">
            <Button
              variant="contained"
              type="submit"
              startIcon={
                <FilterAltOutlinedIcon
                  style={{
                    fill: "#fff",
                  }}
                />
              }
            >
              Filter
            </Button>
            <Button
              onClick={handleResetFilter}
              variant="outlined"
              startIcon={<ClearAllOutlinedIcon />}
            >
              Clear All
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterDates;
