import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  convertCurrencyToSign,
  fShortenNumber,
  PAYMENT_STATUS,
  truncateDescription,
} from "../../utils/constant";
import moment from "moment";
import { useSnackbar } from "notistack";
import { _get_dashboard_data } from "../../DAL/Dashboard/Dashboard";
import { Link, useNavigate } from "react-router-dom";
import { s3BaseUrl } from "../../config/config";
import { makeStyles } from "@mui/styles";
import CustomDrawer from "../../components/CustomDrawer";
import FilterDates from "./FilterDates";
import SearchNotFound from "../../components/notFound/SearchNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "10%",
  },
}));

const currentDate = moment();

let newDateFromCurrentDate = moment(currentDate)
  .subtract(6, "days")
  .format("YYYY-MM-DD");

const EMPTY_FILTER = {
  date_from: newDateFromCurrentDate,
  date_to: moment(currentDate).format("YYYY-MM-DD"),
};

const Dashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [paymentDate, setPaymentDate] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const [inputs, setInputs] = useState(EMPTY_FILTER);
  const currencySign = "GBP";

  if (!inputs.date_from) {
    const newDateFrom = moment(inputs?.date_to, "YYYY-MM-DD")
      .subtract(6, "days")
      .format("YYYY-MM-DD");

    setInputs((prevInputs) => ({
      ...prevInputs,
      date_from: newDateFrom,
    }));
  }

  if (!inputs.date_to) {
    const newDateFrom = moment(currentDate)
      .subtract(6, "days")
      .format("YYYY-MM-DD");

    setInputs((prevInputs) => ({
      ...prevInputs,
      date_from: newDateFrom,
      date_to: moment(currentDate).format("YYYY-MM-DD"),
    }));
  }

  if (!inputs.date_to && !inputs.date_from) {
    setInputs(EMPTY_FILTER);
  }

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);

  const handleQuickInvoiceAddClick = () => {
    navigate("/invoices/add-invoice");
  };

  const handleQuickInvoiceClick = () => {
    navigate("/invoices");
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
  };

  const [userList, setUserList] = useState(
    []

    //   [
    //   {
    //     _id: 1,
    //     invoice_id: "Lec-2152",
    //     client: "Brody Holman",
    //     table_avatar:
    //       "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
    //     email: "metus@protonmail.org",
    //     date: "12 Arl, 2022	",
    //     billed: "240.00",
    //     status: "paid",
    //   },
    //   {
    //     _id: 2,
    //     invoice_id: "Lec-2152",
    //     client: "Brody Holman",
    //     table_avatar:
    //       "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
    //     email: "metus@protonmail.org",
    //     date: "12 Arl, 2022	",
    //     billed: "240.00",
    //     status: "paid",
    //   },
    //   {
    //     _id: 3,
    //     invoice_id: "Lec-2152",
    //     client: "Brody Holman",
    //     table_avatar:
    //       "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
    //     email: "metus@protonmail.org",
    //     date: "12 Arl, 2022	",
    //     billed: "240.00",
    //     status: "paid",
    //   },
    //   {
    //     _id: 3,
    //     invoice_id: "Lec-2152",
    //     client: "Brody Holman",
    //     table_avatar:
    //       "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
    //     email: "metus@protonmail.org",
    //     date: "12 Arl, 2022	",
    //     billed: "240.00",
    //     status: "paid",
    //   },
    // ]
  );

  const TABLE_HEAD = [
    { id: "invoice_no", label: "Invoice ID" },
    {
      id: "client_name",
      label: "Client Name",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-baseline">
              <div className="me-3">
                {row?.profile_image && (
                  <img
                    src={s3BaseUrl + row.profile_image}
                    className="table_avatar"
                  />
                )}
              </div>
              <span>{row.client_name}</span>
            </div>
          </>
        );
      },
    },
    { id: "email", label: "Email" },
    {
      id: "date",
      label: "Date",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-baseline">
              <span>{moment(row.date).format("DD MMM, YYYY")}</span>
            </div>
          </>
        );
      },
    },

    {
      id: "total_amount",
      label: "Billed",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-baseline">
              <span>
                {convertCurrencyToSign(row?.currency)}
                {row?.total_amount ? fShortenNumber(row?.total_amount) : 0}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "payment_status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.payment_status
        );
        return (
          <>
            {find_status ? (
              <span className={`${find_status?.class}`}>
                {find_status?.name}
              </span>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    // {
    //   id: "action",
    //   label: "Action",
    //   type: "action",
    // },
  ];

  const handleClicknew = () => {};

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClicknew,
    },
    {
      label: "View",
      icon: "ep:view",
      handleClick: handleClicknew,
    },
    {
      label: "Download",
      icon: "material-symbols:download",
      handleClick: handleClicknew,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleClicknew,
    },
  ];

  let barChart = {
    options: {
      fill: {
        colors: ["#438a7a", "#F5F5F5", "#9C27B0"],
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      dataLabels: {
        enabled: false, // Keep dataLabels disabled
      },
      chart: {
        id: "bar",
      },
      xaxis: {
        categories:
          paymentDate && paymentDate.length > 0
            ? paymentDate
            : ["Data Not Found"],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return `${convertCurrencyToSign(currencySign)}${fShortenNumber(
              val
            )}`;
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return `${convertCurrencyToSign(currencySign)}${fShortenNumber(
              val
            )}`;
          },
        },
      },
    },
    series: [
      {
        name: "Amount",
        data: paymentAmount, // Your dynamic data here
      },
    ],
  };

  let donutChart = {
    options: {
      colors: ["#438a7a", "#7BADA2", "#858585"],
      fill: {
        colors: ["#438a7a", "#7BADA2", "#858585"],
      },
      markers: {
        colors: ["#438a7a", "#7BADA2", "#858585"],
      },
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      labels: ["Total Amount", "Total Paid Amount", "Total Remaining Amount"],

      dataLabels: {
        enabled: true,
        style: {
          colors: ["#438a7a"],
          fontSize: "10px",
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 5,
          padding: 5,
        },
      },
    },
    series: [
      dashboardData?.total_amount ?? 0,
      dashboardData?.total_paid_amount ?? 0,
      dashboardData?.total_remaining_amount ?? 0,
    ],
  };

  let lineChart = {
    options: {
      chart: {
        type: "line",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#438a7a", "#F5F5F5"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Series 1",
        data: [15, 20, 15, 35, 29, 45, 35, 55, 65, 50, 75, 40],
        color: "#438a7a",
      },
    ],
  };

  const getStatusTooltip = (transaction) => {
    return transaction?.status === "succeded"
      ? "Payment Successful"
      : transaction?.status === "failed"
      ? "Payment Failed"
      : "Unknown Status";
  };

  const get_dashboard_data = async (reset) => {
    let data = reset == "reset" ? EMPTY_FILTER : inputs;
    setIsLoading(true);

    let postData = {
      date_from: data?.date_from
        ? moment(data?.date_from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
      date_to: data?.date_to
        ? moment(data?.date_to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "",
    };

    const result = await _get_dashboard_data(postData);
    console.log("result  dashboard  ___dashboard ___result", result);
    if (result.code === 200) {
      setDashboardData(result);
      setUserList(result?.latest_invoices);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_dashboard_data();
  }, []);

  const handleSearch = (reset) => {
    get_dashboard_data(reset);
  };

  useEffect(() => {
    const dates = dashboardData?.payment_data?.stats?.map((stat) =>
      moment(stat?.date, "DD-MMM-YYYY").format("DD/MM/YYYY")
    );

    const amounts = dashboardData?.payment_data?.stats?.map(
      (stat) => stat.total_amount
    );

    if (dates && amounts) {
      setPaymentDate(dates.reverse());
      setPaymentAmount(amounts.reverse());
    }
  }, [dashboardData?.payment_data]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <CustomDrawer
          isOpenDrawer={filterOpen}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <FilterDates
              inputs={inputs}
              setInputs={setInputs}
              handleSearch={handleSearch}
              handleCloseFilterDrawer={handleCloseFilterDrawer}
              EMPTY_FILTER={EMPTY_FILTER}
            />
          }
        />

        <div className="row">
          {/* <div className="col-12"> */}
          <div className="d-flex align-items-center justify-content-between flex-wrap ">
            <div className="page-title-box mb-3">
              <h4>Dashboard</h4>
            </div>

            <div style={{ marginTop: "-20px" }}>
              <Button
                onClick={handleOpenFilterDrawer}
                variant="contained"
                startIcon={
                  <FilterAltOutlinedIcon
                    style={{
                      fill: "#fff",
                    }}
                  />
                }
              >
                Filter
              </Button>
            </div>
          </div>
          <div className="col-12 col-xl-5 d-flex justify-content-center">
            <div className="card w-100  ">
              <div className="card-body">
                <div className="row align-items-center gy-4 ">
                  <div className="col-sm-8">
                    {/* <h5 className="fs-20">Create a Quick Invoice</h5> */}
                    <h4 className="card-title">Create a Quick Invoice</h4>
                    <p className="text-muted fs-12">
                      Generate an invoice quickly for your best customers and
                      keep track of their payment activity.
                    </p>
                    <Button
                      variant="contained"
                      onClick={handleQuickInvoiceAddClick}
                    >
                      Quick Invoice
                    </Button>
                  </div>
                  <div className="col-sm-4">
                    <div className="text-center px-2">
                      <img
                        src="https://themes.themesbrand.com/invoika/react/v-light/static/media/invoice-widget.0284de2b73f05c16e48b.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-7 d-flex justify-content-center mt-4 mt-xl-0">
            <div className="card w-100">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap">
                  <h4 className="card-title mb-0">Payment Summary</h4>
                  {/* <div className="d-flex align-items-center flex-wrap mt-3 mt-md-0 ">
                    <span className="fw-semibold text-uppercase fs-14 me-1">
                      Sort by:
                    </span>
                    <span className=" text-muted fs-15">
                      Current Week
                      <i
                        className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                        onClick={handleClick}
                      ></i>
                    </span>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                          mt: 1.5,
                        },
                      }}
                    >
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Today
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Last Week
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Last Month
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        {" "}
                        Current Year
                      </MenuItem>
                    </Menu>
                  </div> */}
                </div>
                <div className="row mt-3   mt-xl-4 ">
                  <div className="mini-widget pb-3 pb-lg-0 col-lg-4">
                    <div className="d-flex align-items-end">
                      <div className="flex-grow-1">
                        <h5 className="text-muted fs-14 mb-2 mt-0">
                          Total Amount
                        </h5>

                        <h2 className="mt-0 fs-24">
                          <span>
                            {convertCurrencyToSign(currencySign)}
                            {dashboardData?.total_amount
                              ? fShortenNumber(dashboardData?.total_amount)
                              : 0}
                          </span>
                        </h2>

                        {/* <p className="text-muted mt-3 pt-1 mb-0 text-truncate">
                          {" "}
                          <span className="badge bg-info me-1">1.15%</span>{" "}
                          since last week
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="mini-widget pb-3 pb-lg-0 col-lg-4">
                    <div className="d-flex align-items-end">
                      <div className="flex-grow-1">
                        <h5 className="text-muted fs-14 mb-2 mt-0">
                          Total Paid Amount
                        </h5>

                        <h2 className="mt-0 fs-24">
                          <span>
                            {convertCurrencyToSign(currencySign)}
                            {dashboardData?.total_paid_amount
                              ? fShortenNumber(dashboardData?.total_paid_amount)
                              : 0}
                          </span>
                        </h2>

                        {/* <p className="text-muted mt-3 pt-1 mb-0 text-truncate">
                          {" "}
                          <span className="badge bg-danger me-1">
                            1.15%
                          </span>{" "}
                          since last week
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="mini-widget pb-3 pb-lg-0 col-lg-4">
                    <div className="d-flex align-items-end">
                      <div className="flex-grow-1">
                        <h5 className="text-muted fs-14 mb-2 mt-0">
                          Remaining Amount
                        </h5>

                        <h2 className="mt-0 fs-24">
                          <span>
                            {convertCurrencyToSign(currencySign)}
                            {dashboardData?.total_remaining_amount
                              ? fShortenNumber(
                                  dashboardData?.total_remaining_amount
                                )
                              : 0}
                          </span>
                        </h2>

                        {/* <p className="text-muted mt-3 pt-1 mb-0 text-truncate">
                          {" "}
                          <span className="badge bg-info me-1">3.14%</span>{" "}
                          since last week
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-8 d-flex justify-content-center mt-4">
            <div className="card w-100 ">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap ">
                  <h4 className="card-title mb-0">Sales Dashboard</h4>
                  {/* <div className="d-flex gap-1 flex-wrap mt-3 mt-md-0">
                    <button type="button" className="btn btn-soft-info btn-sm">
                      ALL
                    </button>
                    <button type="button" className="btn btn-soft-info btn-sm">
                      1M
                    </button>
                    <button type="button" className="btn btn-soft-info btn-sm">
                      6M
                    </button>
                    <button type="button" className="btn btn-info btn-sm">
                      1Y
                    </button>
                  </div> */}
                </div>
                <div className="row gy-2 mt-1">
                  <div className="col-md-4">
                    <h4 className="ms-3 fs-18 mb-0">
                      {convertCurrencyToSign(currencySign)}
                      {dashboardData?.payment_data?.weekly_income
                        ? fShortenNumber(
                            dashboardData?.payment_data?.weekly_income
                          )
                        : 0}
                    </h4>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="d-flex main-chart justify-content-end">
                      <div className="px-4 border-end">
                        <h4 className="text_primary fs-22 mb-0">
                          $584k{" "}
                          <span className="text-muted d-inline-block fs-17  ms-0 ms-sm-2">
                            Incomes
                          </span>
                        </h4>
                      </div>
                      <div className="ps-4">
                        <h4 className="text_primary fs-22 mb-0">
                          $324k{" "}
                          <span className="text-muted d-inline-block fs-17  ms-0 ms-sm-2">
                            Expenses
                          </span>
                        </h4>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="mixed-chart">
                  <Chart
                    options={barChart?.options}
                    series={barChart?.series}
                    type="bar"
                    height={380}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4 d-flex justify-content-center mt-4">
            <div className="card w-100 ">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap ">
                  <h4 className="card-title mb-0">Payment Overview</h4>
                  {/* <div className="d-flex  align-items-center flex-wrap mt-3 mt-md-0">
                    <span className="fw-semibold text-uppercase fs-14 me-1">
                      Sort by:{" "}
                    </span>
                    <span className="text-muted fs-15">
                      Weekly
                      <i
                        onClick={handleClick1}
                        className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                      ></i>
                    </span>
                    <Menu
                      anchorEl={anchorEl1}
                      id="account-menu"
                      open={open1}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                          mt: 1.5,
                        },
                      }}
                    >
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Yearly
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Monthly
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Weekly
                      </MenuItem>
                    </Menu>
                  </div> */}
                </div>
                <div className="mixed-chart mt-3">
                  <Chart
                    options={donutChart?.options}
                    series={donutChart?.series}
                    type="donut"
                  />
                </div>
                <div className="px-2">
                  <div className="structure-list d-flex justify-content-between align-items-center  border-bottom">
                    <p className="mb-0">
                      <i className="fa-regular fa-circle-dot fs-18 text_primary me-2" />
                      Total Amount
                    </p>
                    <div>
                      {/* <span className="pe-2 pe-sm-5">
                        {convertCurrencyToSign(currencySign) +
                          (dashboardData?.total_amount ?? 0)}
                      </span> */}
                      <span className="badge bg_primary">
                        {" "}
                        {convertCurrencyToSign(currencySign)}
                        {dashboardData?.total_amount
                          ? fShortenNumber(dashboardData?.total_amount)
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className="structure-list d-flex justify-content-between align-items-center  border-bottom">
                    <p className="mb-0">
                      <i className="fa-regular fa-circle-dot fs-18 text_primary me-2" />
                      Total Paid Amount
                    </p>
                    <div>
                      {/* <span className="pe-2 pe-sm-5">
                        {" "}
                        {convertCurrencyToSign(currencySign) +
                          (dashboardData?.total_paid_amount ?? 0)}
                      </span> */}
                      {/* <span className="badge bg_primary"> + 0.2% </span> */}
                      <span className="badge bg_primary">
                        {" "}
                        {convertCurrencyToSign(currencySign)}
                        {dashboardData?.total_paid_amount
                          ? fShortenNumber(dashboardData?.total_paid_amount)
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className="structure-list d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      <i className="fa-regular fa-circle-dot fs-18 text_primary me-2" />
                      Remaining Amount
                    </p>
                    <div>
                      {/* <span className="pe-2 pe-sm-5">
                        {" "}
                        {convertCurrencyToSign(currencySign) +
                          (dashboardData?.total_remaining_amount ?? 0)}
                      </span> */}
                      {/* <span className="badge bg_primary"> + 0.2% </span> */}
                      <span className="badge bg_primary">
                        {" "}
                        {convertCurrencyToSign(currencySign)}
                        {dashboardData?.total_remaining_amount
                          ? fShortenNumber(
                              dashboardData?.total_remaining_amount
                            )
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-xl-5 d-flex justify-content-center mt-4">
            <div className="card w-100 ">
              <div className="card-body dashboard_input ">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap ">
                  <h4 className="card-title mb-0">Quick Invoice</h4>
                  <div className="d-flex gap-1 flex-wrap mt-3 mt-md-0">
                    <button type="button" className="btn btn-soft-info btn-sm">
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-lg-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Customer Name"
                      variant="outlined"
                      fullWidth
                      placeholder="Customer Name"
                    />
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Customer Email"
                      variant="outlined"
                      fullWidth
                      placeholder="Customer Email"
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      className="textarea-autosize"
                      placeholder="Customer Address"
                      style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "white",
                        color: "#000",
                        borderRadius: "2px",
                        outline: "none",
                        paddingTop: "8px",
                        paddingLeft: "10px",
                        border: "1px solid #00000038",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p>Sub Total</p>
                  <p>$699.96</p>
                </div>
                <div className="d-flex justify-content-between align-items-center ">
                  <p>Estimated Tax (12.5%) </p>
                  <p>$44.99</p>
                </div>
                <div className="d-flex justify-content-between align-items-center ">
                  <p>Discount (15%)</p>
                  <p>- $53.99</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Shipping Charge</p>
                  <p>$65.00</p>
                </div>
                <div className="border-bottom "></div>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p className="fw-semibold ">Total Amount</p>
                  <p className="fw-semibold ">$755.00</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 gap-4">
                  <Button variant="outlined" className="w-100">
                    Add More Details
                  </Button>
                  <Button variant="contained" className="w-100">
                    Send Money
                  </Button>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-12 col-xl-4 d-flex justify-content-center mt-4">
            <div className="card w-100 ">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0">Payment Overview</h4>
                  <i
                    className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                    onClick={handleClick2}
                  ></i>
                  <Menu
                    anchorEl={anchorEl2}
                    id="account-menu"
                    open={open2}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                        mt: 1.5,
                      },
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Monthly
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Yearly
                    </MenuItem>
                  </Menu>
                </div>
                <div className="mixed-chart mt-3">
                  <Chart
                    options={lineChart.options}
                    series={lineChart.series}
                    type="area"
                    height={340}
                  />
                </div>
                <div className="mt-3 text-center row">
                  <div className="col-6 border-end">
                    <div className="my-1">
                      <p className="text-muted text-truncate mb-2">
                        Received Amount
                      </p>
                      <h4 className="mt-2 mb-0 fs-20">$45,070.00</h4>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="my-1">
                      <p className="text-muted text-truncate mb-2">
                        Due Amount
                      </p>
                      <h4 className="mt-2 mb-0 fs-20">$32,400.00</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-xl-8 dashboard_table mt-4">
            {/* <div className="col-12 col-xl-8 dashboard_table d-flex justify-content-center mt-4"> */}
            <div className="card w-100 ">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap ">
                  <h4 className="card-title mb-0">Invoice List</h4>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    className="m-2"
                  >
                    <Link to="/invoices">View All</Link>
                  </Box>
                  {/* <div className="d-flex  align-items-center flex-wrap mt-3 mt-md-0">
                    <span className="fw-semibold text-uppercase fs-14 me-1">
                      Sort by:{" "}
                    </span>
                    <span className="text-muted fs-15">
                      Weekly
                      <i
                        onClick={handleClick5}
                        className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                      ></i>
                    </span>
                    <Menu
                      anchorEl={anchorEl5}
                      id="account-menu"
                      open={open5}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                          mt: 1.5,
                        },
                      }}
                    >
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Yearly
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Monthly
                      </MenuItem>
                    </Menu>
                  </div> */}
                </div>
              </div>
              <CustomTable
                data={userList}
                TABLE_HEAD={TABLE_HEAD}
                MENU_OPTIONS={MENU_OPTIONS}
                // selected={selected}
                // setSelected={setSelected}
                // checkbox_selection={false}
                hide_footer_pagination={true}
                is_hide={true}
              />
            </div>
          </div>

          <div className="col-12 col-xl-4 d-flex justify-content-center mt-4">
            {/* <div className="col-12 col-xl-4 mt-4"> */}
            <div className="card w-100">
              <div className="card-body">
                <div
                  style={{ marginBottom: "2px" }}
                  className=" border-0 d-flex align-items-center justify-content-between"
                >
                  <h4 className="card-title mt-2 mb-4">Latest Transaction</h4>
                  {/* <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ marginTop: "-13px" }}
                  >
                    <Link to="/transactions">View All</Link>
                  </Box> */}

                  {/* <i
                    className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                    onClick={handleClick3}
                  ></i>
                  <Menu
                    anchorEl={anchorEl3}
                    id="account-menu"
                    open={open3}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                        mt: 1.5,
                      },
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Yearly
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Monthly
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Weekly
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px", color: "#0f0f12" }}
                      onClick={handleClose}
                    >
                      Today
                    </MenuItem>
                  </Menu> */}
                </div>
                {/* <p className="text-muted mb-0 mt-2">Recent</p> */}

                {dashboardData?.latest_transactions.map(
                  (transaction, index) => (
                    <div
                      className={`${
                        dashboardData?.latest_transactions.length !== index + 1
                          ? "border-bottom"
                          : ""
                      } sales-history`}
                      key={index}
                    >
                      <div className="d-flex align-items-center align-items-center ">
                        <div className="avatar-title">
                          <i className="fa-brands fa-paypal"></i>
                        </div>
                        <div className="ms-2">
                          <Tooltip
                            title={
                              transaction?.client_name.length > 17
                                ? transaction?.client_name
                                : ""
                            }
                            arrow
                          >
                            <h5 className="fs-15 mb-1 text-truncate">
                              {truncateDescription(
                                transaction?.client_name,
                                17
                              )}
                            </h5>
                          </Tooltip>
                          <p className="fs-14 text-muted mb-0">
                            {moment(transaction?.date).format("DD MMM, YYYY")}
                          </p>
                        </div>
                        {/* <div className="ms-auto">
                          <span className="badge fs-12 bg-danger-subtle text-danger">
                            - $62
                          </span>
                        </div> */}
                        {/* <div className="ms-auto">
                          <span className="badge fs-12 bg-success-subtle text-success">
                            + $45
                          </span>
                        </div> */}

                        {/* getStatusTooltip */}

                        <Tooltip title={getStatusTooltip(transaction)} arrow>
                          <div className="ms-auto">
                            <span
                              className={`badge fs-12 
                            ${
                              transaction.status == "succeded"
                                ? "bg-success-subtle text-success"
                                : transaction.status == "failed"
                                ? "bg-danger-subtle text-danger"
                                : "bg-success-subtle text-success"
                            }
                            `}
                            >
                              {convertCurrencyToSign(transaction?.currency)}
                              {transaction?.transaction_amount
                                ? fShortenNumber(
                                    transaction?.transaction_amount
                                  )
                                : 0}
                            </span>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )
                )}
                {dashboardData &&
                dashboardData?.latest_transactions &&
                dashboardData?.latest_transactions.length == 0 ? (
                  <div className="">
                    <div
                      style={{ marginTop: "20%", marginBottom: "10%" }}
                      className="p-3"
                    >
                      <Typography variant="h5" align="center">
                        No Data Exists
                      </Typography>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-xl-6 d-flex justify-content-center mt-4">
            <div className="card w-100 ">
              <div className="card-body">
                <div className="border-0 d-flex align-items-center justify-content-between flex-wrap ">
                  <h4 className="card-title mb-0">Sales Revenue</h4>
                  <div className="d-flex  align-items-center flex-wrap mt-3 mt-md-0">
                    <span className="fw-semibold text-uppercase fs-14 me-1">
                      Sort by:{" "}
                    </span>
                    <span className="text-muted fs-15">
                      Years
                      <i
                        className="fa-solid fa-chevron-down fs-12 ms-2 pointer"
                        onClick={handleClick4}
                      ></i>
                    </span>
                    <Menu
                      anchorEl={anchorEl4}
                      id="account-menu"
                      open={open4}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
                          mt: 1.5,
                        },
                      }}
                    >
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Yearly
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "15px", color: "#0f0f12" }}
                        onClick={handleClose}
                      >
                        Monthly
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-centered align-middle table-nowrap mb-0 table">
                        <thead>
                          <tr className="text-uppercase">
                            <th>Countrie</th>
                            <th>Order</th>
                            <th>Earning</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/us.1fdd166125432c58f58918cbfdcae2eb.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">US</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/italy.87998fab63421bd0afc283c2f20311ee.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">Italy</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/spain.5f30750a77f9be43ca71b0173638615a.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">Spain</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/french.be9c82a708f3c1555201bc5182574686.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">French</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/russia.a0844ab35745bae26824f327106412ad.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">Russia</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src="https://themes.themesbrand.com/invoika/react/v-light/static/media/ae.3431c43d7640e3d6db1b4755f3600a60.svg"
                                  className="rounded"
                                  alt="user"
                                  height={22}
                                />
                                <div className="flex-grow-1 ms-3">
                                  <p className="mb-0 text-truncate">Arabic</p>
                                </div>
                              </div>
                            </td>
                            <td>6253</td>
                            <td>$26,524</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
