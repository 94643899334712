import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomDrawer from "../../components/CustomDrawer";
import UserFilter from "./UserFilter";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { download_csv_file } from "../../utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { _payment_report_api } from "../../DAL/Payment";
import moment from "moment";
import { useSnackbar } from "notistack";
import { convertCurrencyToSign, date_format } from "../../utils/constant";
import FilteredChip from "../../components/FilteredChip";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const handlePostData = (data) => {
  let postData = { ...data };
  if (postData.users) {
    postData.users = [postData.users.user_id._id];
  }
  if (postData.start_date) {
    postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
  }
  if (postData.end_date) {
    postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
  }
  return postData;
};

const handleChipData = (data) => {
  let chipList = { ...data };
  if (chipList.end_date) {
    let chip_date = `Start Date: ${date_format(
      chipList.start_date
    )} End Date: ${date_format(chipList.end_date)}`;
    chipList.chip_date = {
      chip_label: chip_date,
      chip_value: chip_date,
    };
    delete chipList.start_date;
    delete chipList.end_date;
  }

  if (chipList.max_amount) {
    let chip_amount = `Min Amount: £${chipList.min_amount} Max Amount: £${chipList.max_amount}`;
    chipList.chip_amount = {
      chip_label: chip_amount,
      chip_value: chip_amount,
    };
    delete chipList.min_amount;
    delete chipList.max_amount;
    delete chipList.filter_by_amount;
  }
  delete chipList.filter_by_date;
  return chipList;
};

const headers = [
  { label: "Invocie Id", key: "invoice_no" },
  { label: "Date", key: "created_At" },
  { label: "User", key: "full_name" },
  { label: "Amount", key: "amount" },
];

const SaleReport = () => {
  const classes = useStyles();
  const observer = useRef();
  const componentRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reportList, setReportList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const open = Boolean(anchorEl);
  const load_more = `api/payment_request/payment_report/?page=0&limit=50`;

  const EMPTY_FILTER = {
    users: null,
    start_date: null,
    end_date: null,
    chip_date: null,
    min_amount: "",
    max_amount: "",
    chip_amount: "",
    filter_by_amount: false,
    filter_by_date: false,
  };

  const [inputs, setInputs] = useState(EMPTY_FILTER);
  const [chipData, setChipData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const handleExportClick = () => {
    download_csv_file(headers, reportList);
  };

  const printReportList = useReactToPrint({
    content: () => componentRef.current,
  });

  const get_report_list = async (filter_data, path, array, loading) => {
    if (loading) {
      setIsLoading(true);
    }
    console.log("filter_data  __filter_data", filter_data);
    let postData = handlePostData(filter_data);
    handleCloseFilterDrawer();
    const result = await _payment_report_api(postData, path);
    if (result.code === 200) {
      const chipList = handleChipData(filter_data);
      setInputs((values) => ({
        ...values,
        chip_amount: chipList.chip_amount,
        chip_date: chipList.chip_date,
      }));
      setChipData(chipList);

      let data = result.payment_report.map((item) => {
        let full_name = "N/A";
        let amount = "N/A";
        let created_At = "N/A";
        if (item.client_info) {
          full_name =
            item.client_info?.first_name +
            " " +
            item.client_info?.last_name +
            " (" +
            item.client_info?.email +
            ")";
        }
        if (item.createdAt) {
          created_At = moment(item.createdAt).format("DD-MMMM-YYYY");
        }
        if (item.total_amount) {
          amount =
            convertCurrencyToSign(item.currency) + item.total_amount.toFixed(2);
        }

        return {
          ...item,
          full_name,
          created_At,
          amount,
        };
      });
      let new_array = array.concat(data);
      setReportList(new_array);
      setLoadMorePath(result.load_more_url);
      setTotalPages(result.total_pages);
      setPageNumber((prev) => prev + 1);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoadingMore(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    setPageNumber(0);
    get_report_list(inputs, load_more, [], "true");
    localStorage.setItem("user_filter_data", JSON.stringify(inputs));
  };

  const handleDeleteChip = (data) => {
    setPageNumber(0);
    if (!data.chip_date) {
      data.start_date = null;
      data.end_date = null;
      data.filter_by_date = false;
    }
    if (!data.chip_amount) {
      data.min_amount = "";
      data.max_amount = "";
      data.filter_by_amount = false;
    }
    setInputs(data);
    setChipData(data);
    get_report_list(data, load_more, [], "true");
    localStorage.setItem("user_filter_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setPageNumber(0);
    get_report_list(EMPTY_FILTER, load_more, [], "true");
    setChipData(EMPTY_FILTER);
    setInputs(EMPTY_FILTER);
    localStorage.removeItem("user_filter_data");
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const loadMoreData = () => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("user_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setIsLoadingMore(true);
    get_report_list(filter_data, loadMorePath, reportList);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("user_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setInputs(filter_data);
    setChipData(filter_data);
    get_report_list(filter_data, load_more, [], "true");
  }, []);

  let total_amount = reportList.reduce((acc, item) => {
    return acc + item.total_amount;
  }, 0);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Reports</h4>
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={handleOpenFilterDrawer}
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>
          <button className="btn btn-soft-info" onClick={handleClick}>
            <i className="fa-solid fa-ellipsis-vertical "></i>
          </button>
        </div>
      </div>
      <div className="mt-2">
        <FilteredChip
          data={chipData}
          tempState={inputs}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
      </div>
      <div className="mt-4 card" ref={componentRef}>
        <div className="scrollable-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="p-3" scope="col">
                  #
                </th>
                <th className="p-3" scope="col">
                  Invocie Id
                </th>
                <th className="p-3" scope="col">
                  User
                </th>
                <th className="p-3" scope="col">
                  Date
                </th>
                <th className="p-3 text-end" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {reportList.length > 0 ? (
                <>
                  {reportList.map((item, index) => {
                    return (
                      <tr>
                        <th className="p-3" scope="row">
                          <span>{index + 1}</span>
                        </th>
                        <td className="p-3">
                          <span>{item.invoice_no}</span>
                        </td>
                        <td className="p-3">
                          <span>{item.full_name}</span>
                        </td>
                        <td className="p-3">
                          <span>{item.created_At}</span>
                        </td>
                        <td className="p-3 text-end">
                          <span>{item.amount}</span>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Data Exist
                  </td>
                </tr>
              )}
              {totalPages >= pageNumber ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <span
                      ref={lastBookElementRef}
                      className=" mt-3"
                      onClick={loadMoreData}
                      id="load-more-feed"
                    >
                      {isLoadingMore ? "Loading..." : "Load More"}
                    </span>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
        <div className="me-3 invoice-container">
          <div className="invoice-item">
            Sub Total:{" "}
            <span style={{ float: "right" }}>
              {convertCurrencyToSign("GBP") + total_amount.toFixed(2)}
            </span>
          </div>
          <div className="invoice-total">
            Total Amount:{" "}
            <span style={{ float: "right" }}>
              {convertCurrencyToSign("GBP") + total_amount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={filterOpen}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <UserFilter
            inputs={inputs}
            setInputs={setInputs}
            handleSearch={handleSearch}
            handleSelectOther={handleSelectOther}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
            mt: 1.5,
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={printReportList}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Print
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleExportClick}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Export to Excel
        </MenuItem>
      </Menu>
    </>
  );
};

export default SaleReport;
