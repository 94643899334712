import { invokeApi } from "../invokeApi";

export const _business_client_init_without_token_api = async () => {
  const requestObj = {
    path: `api/business_client_settings/business_client_init_without_token`,
    method: "GET",
  };
  return invokeApi(requestObj);
};

export const _admin_with_token_api = async () => {
  const requestObj = {
    path: `api/business/detail_business`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
