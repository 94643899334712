import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CustomPopover({
  isOpenPop,
  isClosePop,
  title,
  componentToPassDown,
  width,
  imageContainer,
}) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const handleClose = () => {
    isClosePop(false);
  };

  return (
    <Dialog
      open={isOpenPop}
      onClose={handleClose}
      PaperProps={{
        style: {
          minWidth: isMdScreen ? width : "",
        },
      }}
    >
      <div className={`${imageContainer ? "" : "p-3"}`}>
        <div className="d-flex justify-content-between align-items-baseline">
          <div></div>
          {title && (
            <div className="page-title-box">
              <h4>{title}</h4>
            </div>
          )}
          <div className="pointer  " onClick={handleClose}>
            {/* <CloseIcon /> */}
            <span class="popover-close-icon" aria-label="Delete image">
              X
            </span>
          </div>
        </div>
        <div>{componentToPassDown}</div>
      </div>
    </Dialog>
  );
}
