import React from "react";
import PaymentPage from "./PaymentPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { _get_payment_request_stripe_key_api } from "../../DAL/Payment";
import { useAdminContext } from "../../Hooks/AdminContext";

const CheckOut = () => {
  const { adminSettingList } = useAdminContext();
  let stripe_key = adminSettingList.stripe_key;
  return (
    <Elements stripe={loadStripe(stripe_key)}>
      <PaymentPage />
    </Elements>
  );
};

export default CheckOut;
