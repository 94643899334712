import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  _get_fire_settings_by_client_api,
  _updated_fire_settings_by_client_api,
} from "../../DAL/User/User";
import { useAdminContext } from "../../Hooks/AdminContext";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BankPayment() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAdminContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const [inputs, setInputs] = useState({
    fire_refresh_token: "",
    fire_client_id: "",
    fire_account_no: "",
    fire_client_key: "",
    fire_euro_account_no: "",
    fire_payment_request_url: "https://api-preprod.fire.com/",
    fire_redirect_url: "https://payments-preprod.fire.com/",
    live_fire_refresh_token: "",
    live_fire_client_id: "",
    live_fire_account_no: "",
    live_fire_client_key: "",
    live_fire_euro_account_no: "",
    live_fire_payment_request_url: "https://api.fire.com/",
    live_fire_redirect_url: "https://payments.fire.com/",
    fire_mode: "sandBox",
    is_fire_settings_enabled: false,
    fire_payment_fee_percentage: "",
    payee_id: "",
    charges_fee_on: "",
  });

  const get_fire_settings = async () => {
    setIsLoadingForm(true);
    const result = await _get_fire_settings_by_client_api(
      userInfo?.user_id?._id
    );
    if (result.code == 200) {
      let new_obj = {};
      if (result?.fire_settings) {
        new_obj = {
          ...result.fire_settings,
          ...result.fire_settings.fire_settings,
          fire_payment_request_url: "https://api-preprod.fire.com/",
          fire_redirect_url: "https://payments-preprod.fire.com/",
          live_fire_payment_request_url: "https://api.fire.com/",
          live_fire_redirect_url: "https://payments.fire.com/",
        };
      } else {
        new_obj = {
          is_fire_settings_enabled: false,
        };
      }

      setInputs(new_obj);
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      is_fire_settings_enabled: inputs.is_fire_settings_enabled,
      user_id: userInfo?.user_id?._id,
      fire_settings: {},
    };
    if (inputs.is_fire_settings_enabled) {
      postData.fire_settings = {
        fire_refresh_token: inputs.fire_refresh_token,
        fire_client_id: inputs.fire_client_id,
        fire_account_no: inputs.fire_account_no,
        fire_client_key: inputs.fire_client_key,
        fire_euro_account_no: inputs.fire_euro_account_no,
        fire_payment_request_url: inputs.fire_payment_request_url,
        fire_redirect_url: inputs.fire_redirect_url,
        live_fire_refresh_token: inputs.live_fire_refresh_token,
        live_fire_client_id: inputs.live_fire_client_id,
        live_fire_account_no: inputs.live_fire_account_no,
        live_fire_client_key: inputs.live_fire_client_key,
        live_fire_euro_account_no: inputs.live_fire_euro_account_no,
        live_fire_payment_request_url: inputs.live_fire_payment_request_url,
        live_fire_redirect_url: inputs.live_fire_redirect_url,
        fire_mode: inputs.fire_mode,
        fire_payment_fee_percentage: inputs.fire_payment_fee_percentage,
        payee_id: inputs.payee_id,
        charges_fee_on: inputs.charges_fee_on,
      };
    }
    // console.log("postData  ___postData", postData);
    const result = await _updated_fire_settings_by_client_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    get_fire_settings();
  }, []);

  if (isLoadingForm == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="d-flex justify-content-between align-items-center flex-wrap ">
            <div className="page-title-box">
              <h4>Bank Settings</h4>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Bank Settings Enabled
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_fire_settings_enabled"
                value={inputs.is_fire_settings_enabled}
                label="Is Bank Settings Enabled"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_fire_settings_enabled && (
            <>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Payment Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="fire_mode"
                    value={inputs.fire_mode}
                    label="Payment Mode"
                    onChange={handleChange}
                  >
                    <MenuItem value="sandBox">SandBox</MenuItem>
                    <MenuItem value="live">Live</MenuItem>
                  </Select>
                </FormControl>
              </div> */}

              {/* <div className="page-title-box mt-3">
                <h4>Bank Settings for SandBox</h4>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Client Key"
                  variant="outlined"
                  fullWidth
                  placeholder="Bank Client Key"
                  required
                  name="fire_client_key"
                  value={inputs.fire_client_key}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Refresh Token"
                  variant="outlined"
                  fullWidth
                  placeholder="Bank Refresh Token"
                  required
                  name="fire_refresh_token"
                  value={inputs.fire_refresh_token}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Client Id"
                  placeholder="Bank Client Id"
                  variant="outlined"
                  fullWidth
                  required
                  name="fire_client_id"
                  value={inputs.fire_client_id}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Sterling Account No(GBP)"
                  placeholder="Sterling Account No(GBP)"
                  variant="outlined"
                  fullWidth
                  required
                  name="fire_account_no"
                  value={inputs.fire_account_no}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Euro Account No (EUR)"
                  placeholder="Euro Account No (EUR)"
                  variant="outlined"
                  fullWidth
                  required
                  name="fire_euro_account_no"
                  value={inputs.fire_euro_account_no}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Payment Request Url"
                  placeholder="Bank Payment Request Url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  required
                  name="fire_payment_request_url"
                  value={inputs.fire_payment_request_url}
                  onChange={handleChange}
                  disabled
                />
              </div> */}
              {/* <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Redirect Url"
                  placeholder="Bank Redirect Url"
                  variant="outlined"
                  fullWidth
                  required
                  type="url"
                  name="fire_redirect_url"
                  value={inputs.fire_redirect_url}
                  disabled
                  onChange={handleChange}
                />
              </div> */}
              <div className="page-title-box mt-3">
                <h4>Bank Settings for Live</h4>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Client Key"
                  placeholder="Bank Client Key"
                  variant="outlined"
                  fullWidth
                  required
                  name="live_fire_client_key"
                  value={inputs.live_fire_client_key}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Refresh Token"
                  placeholder="Bank Refresh Token"
                  variant="outlined"
                  fullWidth
                  required
                  name="live_fire_refresh_token"
                  value={inputs.live_fire_refresh_token}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Client Id"
                  placeholder="Bank Client Id"
                  variant="outlined"
                  fullWidth
                  required
                  name="live_fire_client_id"
                  value={inputs.live_fire_client_id}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Sterling Account No(GBP)"
                  placeholder="Sterling Account No(GBP)"
                  variant="outlined"
                  fullWidth
                  required
                  name="live_fire_account_no"
                  value={inputs.live_fire_account_no}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Euro Account No (EUR)"
                  placeholder="Euro Account No (EUR)"
                  variant="outlined"
                  fullWidth
                  required
                  name="live_fire_euro_account_no"
                  value={inputs.live_fire_euro_account_no}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Payment Request Url"
                  placeholder="Bank Payment Request Url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  required
                  name="live_fire_payment_request_url"
                  value={inputs.live_fire_payment_request_url}
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Bank Redirect Url"
                  placeholder="Bank Redirect Url"
                  variant="outlined"
                  fullWidth
                  required
                  type="url"
                  disabled
                  name="live_fire_redirect_url"
                  value={inputs.live_fire_redirect_url}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          <div className="text-end mt-3">
            <Button type="submit" variant="contained">
              {isLoading ? "Updating" : "Update"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
