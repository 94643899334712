import React, { useMemo, useState } from "react";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import countryList from "react-select-country-list";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const AddorUpdatedInvoice = ({ value }) => {
  const [paymentType, setPaymentType] = useState([
    { option: "Paid" },
    { option: "Refund" },
    { option: "cancel" },
  ]);
  const [billingCheck, setBillingCheck] = useState(false);

  const handleChangeCheck = () => {
    setBillingCheck(!billingCheck);
  };

  const options = useMemo(() => countryList().getData(), []);

  const handleClick = () => {};

  let breadCrumbMenu = [
    {
      title: "Invoice",
      navigation: -1,
      active: false,
    },
    {
      title: value ? "Edit Invoice" : "Add Invoice",
      active: true,
    },
  ];
  return (
    <div className="container">
      <div className="d-flex justify-content-between flex-wrap">
        <div className="page-title-box">
          <h4>{value ? "Edit Invoice" : "Add Invoice"} </h4>
        </div>
        <div>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <TextField
            id="outlined-basic"
            label="Invoice No"
            variant="outlined"
            fullWidth
            placeholder="Invoice No"
            disabled
            defaultValue="#VL25000355"
          />
        </div>
        <div className="col-6">
          <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
            <DatePicker label="Date" fullWidth />
          </LocalizationProvider>
        </div>
        <div className="col-6 mt-3">
          <MUIAutocomplete
            inputLabel="Payment Status"
            // selectedOption={}
            setSelectedOption={(e) => {
              handleClick(e);
            }}
            optionsList={paymentType}
            // setCustomSearch={}
            name="option"
          />
        </div>
        <div className="col-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            variant="outlined"
            fullWidth
            placeholder="Total Amount"
          />
        </div>
        <div className="col-12 mt-4">
          <div className="page-title-box">
            <h4>Billing Address</h4>
          </div>
        </div>
        <div className="col-6">
          <MUIAutocomplete
            inputLabel="Country"
            // selectedOption={}
            setSelectedOption={(e) => {
              handleClick(e);
            }}
            optionsList={options}
            // setCustomSearch={}
            name="label"
          />
        </div>
        <div className="col-6">
          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            placeholder="City"
          />
        </div>
        <div className="col-6 mt-3">
          <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            fullWidth
            placeholder="State"
          />
        </div>
        <div className="col-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Address"
            variant="outlined"
            fullWidth
            placeholder="Address"
          />
        </div>
        <div className="col-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Zip Code"
            variant="outlined"
            fullWidth
            placeholder="Zip Code"
          />
        </div>
        <div className="col-12 mt-3">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Will your Billing and Shipping address same?"
            checked={billingCheck}
            onChange={handleChangeCheck}
          />
        </div>
        {!billingCheck && (
          <>
            <div className="col-12 mt-4">
              <div className="page-title-box">
                <h4>Shipping Address</h4>
              </div>
            </div>
            <div className="col-6">
              <MUIAutocomplete
                inputLabel="Country"
                // selectedOption={}
                setSelectedOption={(e) => {
                  handleClick(e);
                }}
                optionsList={options}
                // setCustomSearch={}
                name="label"
              />
            </div>
            <div className="col-6">
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                fullWidth
                placeholder="City"
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                fullWidth
                placeholder="State"
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                fullWidth
                placeholder="Address"
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Zip Code"
                variant="outlined"
                fullWidth
                placeholder="Zip Code"
              />
            </div>
          </>
        )}
        <div className="col-12 mt-4">
          <div className="d-flex justify-content-end gap-3">
            <button className="btn btn-info">
              <i className="fa-regular fa-floppy-disk me-1 text-white "></i>{" "}
              Save
            </button>
            <a className="btn btn-primary">
              <i className="fa-solid fa-download me-1 text-white"></i> Download
              Invoice
            </a>
            <a className="btn btn-danger">
              <i className="fa-regular fa-share-from-square me-1 text-white"></i>{" "}
              Send Invoice
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddorUpdatedInvoice;
