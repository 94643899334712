import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useAdminContext } from "../Hooks/AdminContext";
import { Helmet } from "react-helmet-async";
import { s3BaseUrl } from "../config/config";
import { _business_client_init_without_token_api } from "../DAL/Setting/Setting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function LoginLayout() {
  const classes = useStyles();
  const { handleSettingList, adminSettingList } = useAdminContext();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const get_setting_data = async () => {
    setIsLoading(true);
    const result = await _business_client_init_without_token_api();
    if (result.code == 200) {
      handleSettingList(result?.data?.business_client_settings);
      localStorage.setItem(
        "gernal_settings",
        JSON.stringify(result?.data?.business_client_settings?.gerenal_settings)
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_setting_data();
  }, []);

  const general_setting = adminSettingList.gerenal_settings;

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <Helmet>
        <title>{general_setting?.meta_title}</title>
        <meta name="description" content={general_setting?.meta_discription} />
        <link rel="icon" href={s3BaseUrl + general_setting?.admin_favicon} />
      </Helmet>
      <Outlet />
    </>
  );
}
