import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import AppSideBar from "./AppSideBar";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { _admin_with_token_api } from "../../DAL/Setting/Setting";
import { useAdminContext } from "../../Hooks/AdminContext";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet-async";
import { s3BaseUrl } from "../../config/config";
import { _set_user_in_localStorage } from "../../local_storage/local_storage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// const newobj = {
//   icon: "images/47fcde90-015f-11ef-9918-7188abc77a23.png",
//   is_link: false,
//   is_open_new_tab: false,
//   link: "link",
//   order: 1,
//   path: "/bank-payment-settings",
//   title: "Bank Payment Settings",
//   value: "bank-payment-settings",
//   _id: "411105a9c1e943f6a8a416a43417203523",
// };

export default function DashboardLayout() {
  const classes = useStyles();
  const { handleSettingList, adminSettingList, setNavItemList } =
    useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const pathname = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const get_setting_data = async () => {
    setIsLoading(true);
    const result = await _admin_with_token_api();
    if (result?.code == 200) {
      handleSettingList(result?.general_settings);
      setNavItemList(result?.nav_items);
      localStorage.setItem(
        "gernal_settings",
        JSON.stringify(result?.general_settings)
      );
      _set_user_in_localStorage(result?.user);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result?.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      setIsLoading(false);
    } else {
      get_setting_data();
    }
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <Helmet>
        <title>{adminSettingList?.meta_title}</title>
        <meta name="description" content={adminSettingList?.meta_discription} />
        <link rel="icon" href={s3BaseUrl + adminSettingList?.admin_favicon} />
      </Helmet>
      <div className="d-flex position-relative">
        <AppHeader
          handleDrawerToggle={handleDrawerToggle}
          setIsLoading={setIsLoading}
        />
        <AppSideBar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </div>
    </>
  );
}
