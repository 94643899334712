import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { Autocomplete, Avatar, Box, TextareaAutosize } from "@mui/material";
import { CustomPhoneInput } from "../../components/CustomPhoneInput";
import { _edit_profile_api } from "../../DAL/Login/Login";
import { s3BaseUrl } from "../../config/config";
import { useAdminContext } from "../../Hooks/AdminContext";
import { UserIcon } from "../../assets";
import { countries } from "../../utils/country";
import { validateEmail } from "../../utils/constant";
import { isValidNumber } from "libphonenumber-js";
import {
  _delete_files_from_s3,
  _empty_image_path_in_business,
} from "../../DAL/SupportTicket";

const Input = styled("input")({
  display: "none",
});

function EditProfile() {
  const { userInfo, setUserInfo } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState(null);
  const [imageRemove, setImageRemove] = useState(false);
  const [imageRemovePath, setImageRemovePath] = useState("");
  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });

  const [phoneNumber, setPhoneNumber] = useState("+44");

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
    email: "",
    oldimage: "",
    status: true,
    profile_image: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const isValid = isValidNumber(`+${inputs?.contact_number}`);
    if (!isValid) {
      return enqueueSnackbar("Please enter valid phone number", {
        variant: "error",
      });
    }

    if (!validateEmail(inputs?.email)) {
      return enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("address", inputs.address);
    formData.append("contact_number", inputs.contact_number);
    formData.append("email", inputs.email);
    formData.append("country", country ? country.code : "");
    formData.append("status", inputs.status);

    if (file) {
      formData.append("image", inputs.profile_image);
    }

    if (imageRemove) {
      const resultImage = await _empty_image_path_in_business(userInfo?._id);
      if (resultImage.code === 200) {
        const result = await _edit_profile_api(formData);
        if (result.code === 200) {
          setUserInfo(result.business);
          localStorage.setItem("user", JSON.stringify(result.business));
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else {
        enqueueSnackbar(resultImage.message, { variant: "error" });
      }
    } else {
      const result = await _edit_profile_api(formData);
      if (result.code === 200) {
        setUserInfo(result.business);
        localStorage.setItem("user", JSON.stringify(result.business));
        enqueueSnackbar(result.message, { variant: "success" });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleUpload = (event) => {
    setImageRemove(false);
    setProfileImage(event.target.files[0]);
    setInputs((prev) => ({
      ...prev,
      ["profile_image"]: event.target.files[0],
    }));

    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleRemoveImage = () => {
    setImageRemove(true);
    setProfileImage(null);
    setInputs((prev) => ({
      ...prev,
      ["profile_image"]: null,
      ["oldimage"]: null,
    }));
    setPreviews("");
  };

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      ["first_name"]: userInfo?.first_name,
      ["last_name"]: userInfo?.last_name,
      ["address"]: userInfo?.address,
      ["contact_number"]: userInfo?.contact_number,
      ["oldimage"]: userInfo?.image,
      ["email"]: userInfo?.email,
    }));
    let find_country = countries?.find(
      (item) => item.code === userInfo.country
    );
    if (find_country) {
      setCountry(find_country);
    }
    setPhoneNumber(userInfo?.contact_number ? userInfo?.contact_number : "+44");
  }, [userInfo]);

  return (
    <div className="container">
      <form onSubmit={handleUpdate}>
        <div className="mt-3 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              {/* <div className="edit-profile-icon">
                <img
                  src={
                    previews
                      ? previews
                      : inputs.oldimage
                      ? s3BaseUrl + inputs.oldimage
                      : UserIcon
                  }
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div> */}

              <div className="edit-profile-icon">
                <div style={{ position: "relative", display: "inline-block" }}>
                  {/* <img
                    src={
                      previews
                        ? previews
                        : inputs.oldimage
                        ? s3BaseUrl + inputs.oldimage
                        : UserIcon
                    }
                    height="100"
                    width="100"
                    alt="Profile"
                  /> */}

                  <Avatar
                    src={
                      previews
                        ? previews
                        : inputs.oldimage
                        ? s3BaseUrl + inputs.oldimage
                        : UserIcon
                    }
                    alt={userInfo?.first_name}
                    sx={{ width: 100, height: 100 }}
                  />
                  {previews || inputs.oldimage ? (
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: -12,
                        right: -8,
                      }}
                      onClick={handleRemoveImage}
                      aria-label="remove picture"
                    >
                      <CancelIcon className="image-remove-cross-button" />
                    </IconButton>
                  ) : null}
                </div>
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>

              <div className="row w-100 mt-3">
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="First Name"
                    name="first_name"
                    value={inputs.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Last Name"
                    name="last_name"
                    value={inputs.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <CustomPhoneInput
                    required={true}
                    inputState={inputs}
                    setInputsState={setInputs}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    autoHighlight
                    value={country ? country : null}
                    onChange={(event, newValue) => {
                      setCountry(newValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        required
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </div>

                <div className="col-12 mt-3">
                  {/* <div className="mb-1 fw-14 ms-1">Your Address </div> */}
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Address"
                    multiline
                    fullWidth
                    maxRows={4}
                    placeholder="Address"
                    name="address"
                    value={inputs.address}
                    onChange={handleChange}
                    className="multiline"
                  />

                  {/* <TextareaAutosize
                    aria-label="empty textarea"
                    className="textarea-autosize"
                    placeholder="Address"
                    name="address"
                    value={inputs.address}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      height: "100px",
                      backgroundColor: "white",
                      color: "#000",
                      borderRadius: "5px",
                      outline: "none",
                      paddingTop: "8px",
                      paddingLeft: "10px",
                      border: "1px solid #00000038",
                    }}
                  /> */}
                </div>
              </div>
              <div className="mt-2 ms-auto me-2">
                <button className="btn btn-primary mt-4 " disabled={isLoading}>
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
