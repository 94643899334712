import React, { useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "../../assets";
import { s3BaseUrl } from "../../config/config";
import CustomPopoverSection from "../../components/menuOption/CustomPopoverSection";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useSnackbar } from "notistack";
import {
  _add_support_ticket_comment_api,
  _delete_support_ticket_comment_api,
  _update_support_ticket_comment_api,
  _upload_image_s3_api,
} from "../../DAL/SupportTicket";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Button } from "@mui/material";
import CustomPopover from "../../components/CustomPopover";

const ChatCard = ({ ticketData, commentData, setCommentData }) => {
  const { userInfo } = useAdminContext();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [files, setFiles] = useState([]);
  const params = useParams();
  const [oldImagesArray, setOldImagesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const [resetData, setResetData] = useState(true);
  const [showButtons, setShowButtons] = useState({
    addButton: true,
    editButton: false,
  });
  const [openImage, setOpenImage] = useState(false);
  const [imageData, setImageData] = useState("");
  const [inputs, setInputs] = useState({
    message: "",
  });

  const handleUpload = (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const arr = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
        extension: fileList[i].type,
      };
      arr.push(setImageObject);
    }
    setFiles((prevFiles) => [...prevFiles, ...arr]);
  };

  const handleRemove = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const handleCancelUpdate = () => {
    setInputs({ message: "" });
    setFiles([]);
    setCommentId("");
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setCommentId(value._id);
  };

  const handleClickEdit = (value) => {
    setResetData(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    setShowButtons({
      addButton: false,
      editButton: true,
    });
    setCommentId(value._id);
    setInputs({ message: value.message });
    let setImageObject = {};
    value.comment_image?.map((images, index) => {
      setImageObject = {
        thumbnail_1: images,
        thumbnail_2: images,
        type: "image",
      };
      files.push(setImageObject);
      oldImagesArray.push(setImageObject);
    });
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_support_ticket_comment_api(commentId);
    if (result.code === 200) {
      setCommentData(commentData.filter((item) => item._id !== commentId));
      setDelLoading(false);
      setOpenDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  const getCommentImageShow = (file) => {
    const ext = file.split(".").pop();
    if (
      ext == "jpg" ||
      ext == "JPG" ||
      ext == "png" ||
      ext == "webp" ||
      ext == "jpeg" ||
      ext == "JPEG" ||
      ext == "PNG"
    ) {
      return s3BaseUrl + file;
    } else if (imageLinks[ext]) {
      return imageLinks[ext];
    } else {
      return imageLinks.other;
    }
  };

  const handleOpenImage = (image, type = 1) => {
    if (type == 2) {
      setImageData(image);
    } else {
      setImageData(s3BaseUrl + image);
    }
    setOpenImage(true);
    console.log("image _image", image);
  };

  const getCommentImage = (file) => {
    if (file.type === "file") {
      const ext = file.path.name.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return URL.createObjectURL(file.path);
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    } else {
      const ext = file?.thumbnail_1?.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return s3BaseUrl + file.thumbnail_2;
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const results = files.map((image, index) => {
      const formData = new FormData();
      formData.append("image", image.path);
      const result = _upload_image_s3_api(formData);
      return result;
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results.map((image_path, index) => {
        setImageArray.push(image_path.path);
      });
      const formDataObject = {
        support_ticket_id: params.id,
        message: inputs.message,
      };
      if (files.length > 0) {
        formDataObject.comment_image = setImageArray;
      }

      const result = await _add_support_ticket_comment_api(
        JSON.stringify(formDataObject)
      );
      if (result.code === 200) {
        setResetData(true);
        setIsLoading(false);
        setCommentData([...commentData, result.support_ticket_comment]);
        setInputs({ message: "" });
        setFiles([]);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const deleted_images_array = [];
    let deleted_images_object = {};
    oldImagesArray.map((old_image, index) => {
      const filtered_array = files.filter(
        (image, index) => image.thumbnail_1 == old_image.thumbnail_1
      );
      delete old_image.type;
      if (filtered_array.length == 0) {
        deleted_images_array.push(old_image);
      }
    });
    if (deleted_images_array.length > 0) {
      deleted_images_object = {
        moment_image: deleted_images_array,
      };
    }
    const results = files.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = _upload_image_s3_api(formData);
        return result;
      } else {
        return image;
      }
    });
    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results.map((image_path, index) => {
        if (image_path.path) {
          setImageArray.push(image_path.path);
        } else {
          setImageArray.push(image_path.thumbnail_1);
        }
      });
      const formDataObject = {
        message: inputs.message,
      };
      if (files.length > 0) {
        formDataObject.comment_image = setImageArray;
      }
      const result = await _update_support_ticket_comment_api(
        formDataObject,
        commentId
      );
      if (result.code === 200) {
        setResetData(true);
        setCommentData(
          commentData.map((item) => {
            if (item._id == commentId) {
              return result.support_ticket_comment;
            } else {
              return item;
            }
          })
        );
        setInputs({ message: "" });
        setFiles([]);
        setCommentId("");
        setShowButtons({
          addButton: true,
          editButton: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  return (
    <>
      <CustomPopover
        isOpenPop={openImage}
        isClosePop={setOpenImage}
        // title="View Full Image"
        title=""
        width="600px"
        imageContainer={true}
        componentToPassDown={
          <>
            <div className="d-flex justify-content-center">
              <img
                className="p-0  "
                style={{ maxWidth: "100%" }}
                src={imageData}
                alt="full image"
              />
            </div>
          </>
        }
      />

      <div className="row card">
        <div className="card-body">
          {commentData.length > 0 &&
            commentData.map((item, index) => {
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center ">
                      <Avatar
                        alt="Remy Sharp"
                        src={s3BaseUrl + item?.action_by?.profile_image}
                        onClick={() =>
                          handleOpenImage(item?.action_by?.profile_image)
                        }
                        className="pointer"
                      />
                      <div className="ms-2">
                        <span className="mb-0 fw-14 fw-bold">
                          {item.action_by?.first_name +
                            " " +
                            item.action_by?.last_name}
                        </span>
                        <p className="fw-14 mb-0">{item.action_by?.email}</p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="me-2 fw-14">
                        {moment(item.action_date).format(
                          // "DD-MMMM-YYYY hh:mm A"
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </span>
                      {ticketData.ticket_status !== 0 ? (
                        ""
                      ) : (
                        <>
                          {item.action_by.user_id === userInfo.user_id._id && (
                            <CustomPopoverSection
                              menu={MENU_OPTIONS}
                              data={item}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="m-3 mt-4">
                    <div dangerouslySetInnerHTML={{ __html: item.message }} />
                  </div>
                  <div className="d-flex flex-wrap">
                    {item.comment_image &&
                      item.comment_image.map((image, i) => {
                        const ext = image.split(".").pop();
                        if (
                          ext == "jpg" ||
                          ext == "JPG" ||
                          ext == "png" ||
                          ext == "webp" ||
                          ext == "jpeg" ||
                          ext == "JPEG" ||
                          ext == "PNG"
                        ) {
                          return (
                            <span
                              onClick={() => handleOpenImage(image)}
                              className="preview preview_card pointer"
                              key={i}
                            >
                              <img className="p-0" src={s3BaseUrl + image} />
                            </span>
                          );
                        } else {
                          return (
                            <span
                              onClick={() => handleOpenImage(image)}
                              className="preview preview_card pointer"
                              key={i}
                            >
                              <a
                                href={s3BaseUrl + image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={getCommentImageShow(image)} />
                              </a>
                            </span>
                          );
                        }
                      })}
                  </div>
                  <hr />
                </>
              );
            })}
          <form onSubmit={showButtons.addButton ? handleSubmit : handleUpdate}>
            <div className="col-12 pt-3" ref={ref}>
              <span>Enter Message *</span>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="message"
                editorHeight={320}
                resetData={resetData}
                setResetData={setResetData}
              />
            </div>
            <div className="col-12 mt-3 message_chip">
              <div className="w-100 px-3 d-flex">
                <div className="row w-100 mb-3 add-photo">
                  <p className="mt-2">Recommended Size (1000x670)</p>
                  {files &&
                    files.map((file, index) => (
                      <div className="col-sm-3 col-md-2 col-lg-1 mt-2 ms-2 p-0">
                        <span className="preview">
                          <span
                            onClick={() => {
                              handleRemove(index);
                            }}
                          >
                            x
                          </span>
                          <img
                            className="pointer"
                            onClick={() =>
                              handleOpenImage(getCommentImage(file), 2)
                            }
                            alt={getCommentImage(file)}
                            src={getCommentImage(file)}
                          />
                        </span>
                      </div>
                    ))}
                  <div className="col-sm-3 col-md-2 col-lg-1 mt-2">
                    <span className="upload-button">
                      <input
                        color="primary"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                        multiple={true}
                        accept="image/*,.pdf,.xlsx,.xls,.docx,.csv,.doc"
                      />
                      <label htmlFor="icon-button-file">
                        <CloudUploadIcon />
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex  justify-content-end ">
                {showButtons.addButton && (
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={ticketData.ticket_status !== 0}
                  >
                    {isLoading ? "Loading..." : "Send"}
                  </Button>
                )}
                {showButtons.editButton === true && (
                  <div className="d-flex justify-content-end mb-4 me-4 gap-3 ">
                    <Button variant="outlined" onClick={handleCancelUpdate}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      {isLoading ? "Updating..." : "Update"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        isLoading={delLoading}
        title={"Are you sure you want to remove this comment ?"}
        handleAgree={handleDelete}
      />
    </>
  );
};

export default ChatCard;
