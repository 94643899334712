import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Label from "../../../components/Label";
import moment from "moment/moment";

const OpenTicket = ({ ticketList, handleClickDetail }) => {
  const [dataList, setDataList] = useState([]);

  const get_ticket_list = async () => {
    if (ticketList.length > 0) {
      let data = ticketList.map((item) => {
        let created_at = "N/A";
        if (item.support_ticket_date) {
          created_at = moment(item.support_ticket_date).fromNow();
        }
        return {
          ...item,
          created_at,
        };
      });
      setDataList(data);
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "subject",
      label: "Subject",
      renderData: (row) => {
        return (
          <>
            <span
              className="pointer"
              onClick={() => {
                handleClickDetail(row);
              }}
            >
              {row.subject}
            </span>
          </>
        );
      },
    },
    {
      id: "department",
      label: "Department",
      renderData: (row) => {
        return (
          <>
            <span>{row.department ? row.department.name : "N/A"}</span>
          </>
        );
      },
    },
    { id: "created_at", label: "Created" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let ticket_status = row?.ticket_status;
        let response_status = row?.response_status;
        return (
          <>
            <Label
              variant="ghost"
              className={
                ticket_status === 0 && response_status == 1
                  ? "answer-ticket"
                  : ticket_status == 1
                  ? "solved-ticket"
                  : ticket_status == 2
                  ? "trash-ticket"
                  : "pending-ticket"
              }
            >
              {ticket_status === 0 && response_status == 1
                ? "Answer"
                : ticket_status == 1
                ? " Solved"
                : ticket_status == 2
                ? "Trash"
                : "Waiting"}
            </Label>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_ticket_list();
  }, [ticketList]);

  return (
    <>
      <CustomTable
        data={dataList}
        TABLE_HEAD={TABLE_HEAD}
        is_hide={true}
        pagePagination={false}
      />
    </>
  );
};

export default OpenTicket;
