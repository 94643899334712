import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useAdminContext } from "../Hooks/AdminContext";
import { Helmet } from "react-helmet-async";
import { s3BaseUrl } from "../config/config";
import { _get_payment_request_stripe_key_api } from "../DAL/Payment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function FullPageLayout() {
  const classes = useStyles();
  const params = useParams();
  const { handleSettingList, adminSettingList } = useAdminContext();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const get_payment_data = async () => {
    setIsLoading(true);
    const result = await _get_payment_request_stripe_key_api(params?.id);
    if (result.code === 200) {
      handleSettingList(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const general_setting = adminSettingList.gerenal_settings;

  useEffect(() => {
    get_payment_data();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <Helmet>
        <title>{general_setting?.meta_title}</title>
        <meta name="description" content={general_setting?.meta_discription} />
        <link rel="icon" href={s3BaseUrl + general_setting?.admin_favicon} />
      </Helmet>
      <Outlet />
    </>
  );
}
