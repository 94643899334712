import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { _login_by_admin_api } from "../DAL/Login/Login";
import { useAdminContext } from "../Hooks/AdminContext";
import {
  _set_token_in_localStorage,
  _set_user_in_localStorage,
} from "../local_storage/local_storage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginByAdmin() {
  const classes = useStyles();
  const params = useParams();
  const { setNavItemList } = useAdminContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitLogin = async () => {
    setIsLoading(true);
    const data = {
      token: params.token,
    };
    const result = await _login_by_admin_api(data);
    if (result.code === 200) {
      _set_token_in_localStorage(result.token);
      _set_user_in_localStorage(result.user);
      setNavItemList(result.user.nav_items);
      navigate(`/dashboard`, { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/login");
    }
  };

  useEffect(() => {
    handleSubmitLogin();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
}
