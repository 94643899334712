import React, { useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { PAYMENT_STATUS } from "../../utils/constant";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { Avatar, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const Invoice = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [userList, setUserList] = useState([
    {
      _id: 1,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 2,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 3,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 3,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 4,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 5,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 6,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 7,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 8,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 9,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 3,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
    {
      _id: 10,
      invoice_id: "Lec-2152",
      client: "Brody Holman",
      table_avatar:
        "https://themes.themesbrand.com/invoika/react/v-light/static/media/avatar-2.f4fa3aef6955e50df831.jpg",
      email: "metus@protonmail.org",
      date: "12 Arl, 2022	",
      billed: "240.00",
      status: "paid",
    },
  ]);

  const TABLE_HEAD = [
    { id: "invoice_id", label: "Invoice ID" },
    {
      id: "client",
      label: "Client",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-baseline">
              <div className="me-3">
                <img src={row.table_avatar} className="table_avatar" />
              </div>
              <p>{row.client}</p>
            </div>
          </>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "date", label: "Date" },
    { id: "billed", label: "Billed" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            <span className={`${find_status.class}`}>{find_status.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      type: "action",
    },
  ];

  const handleClickEdit = () => {
    navigate(`/invoice/edit-invoice/1234`);
  };

  const handleClick = () => {};

  const handleClickNavigate = () => {
    navigate(`/invoice/add-invoice`);
  };

  const handleClickDetail = () => {
    navigate(`/invoice/invoice-details`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "View",
      icon: "ep:view",
      handleClick: handleClickDetail,
    },
    {
      label: "Download",
      icon: "material-symbols:download",
      handleClick: handleClick,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleClick,
    },
  ];
  return (
    <>
      <div className="page-title-box">
        <h4>Invoice</h4>
      </div>
      <div className="d-lg-flex align-items-center">
        <Button
          onClick={handleClickNavigate}
          variant="contained"
          startIcon={
            <AddIcon
              style={{
                fill: "#fff",
              }}
            />
          }
        >
          Add Invoice
        </Button>
        <div className="position-relative search_input_table dashboard_input ms-auto mt-lg-0 mt-3">
          <input
            type="text"
            className="form-control ps-3"
            placeholder="Search..."
            id="search-options"
          />
          <i className="fa-solid fa-magnifying-glass search_icon"></i>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-3 col-md-6 mb-2">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between ">
                <div>
                  <h4 className="fs-22 fw-semibold mb-2 ">
                    <span>$559.25k</span>
                  </h4>
                  <p className="text-uppercase fw-medium fs-14 text-muted mb-0">
                    Invoices Sent
                    <span className="text-success fs-14 mb-0 ms-1">
                      <NorthEastIcon
                        style={{
                          fontSize: "14px",
                          marginRight: "8px",
                        }}
                      />
                      +89.24 %
                    </span>
                  </p>
                </div>
                <Avatar sx={{ bgcolor: "#F5F5F5" }}>
                  <i className="fa-solid fa-file-invoice text_primary"></i>
                </Avatar>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="badge bg_primary me-1">2,258</span>{" "}
                  <span className="text-muted">Invoices sent</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-2">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between ">
                <div>
                  <h4 className="fs-22 fw-semibold mb-2 ">
                    <span>$409.66k</span>
                  </h4>
                  <p className="text-uppercase fw-medium fs-14 text-muted mb-0">
                    PAID INVOICES
                    <span className="text-danger fs-14 mb-0 ms-1">
                      <SouthEastIcon
                        style={{
                          fontSize: "14px",
                          marginRight: "8px",
                          fill: "#ed4343",
                        }}
                      />
                      +8.09 %
                    </span>
                  </p>
                </div>
                <Avatar sx={{ bgcolor: "#F5F5F5" }}>
                  <i className="fa-solid fa-check text_primary"></i>
                </Avatar>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="badge bg-danger me-1">2,258</span>{" "}
                  <span className="text-muted">Invoices sent</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-2">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between ">
                <div>
                  <h4 className="fs-22 fw-semibold mb-2 ">
                    <span>$559.25k</span>
                  </h4>
                  <p className="text-uppercase fw-medium fs-14 text-muted mb-0">
                    Invoices Sent
                    <span className="text-success fs-14 mb-0 ms-1">
                      <NorthEastIcon
                        style={{
                          fontSize: "14px",
                          marginRight: "8px",
                        }}
                      />
                      +89.24 %
                    </span>
                  </p>
                </div>
                <Avatar sx={{ bgcolor: "#F5F5F5" }}>
                  <i className="fa-solid fa-file-invoice text_primary"></i>
                </Avatar>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="badge bg_primary me-1">2,258</span>{" "}
                  <span className="text-muted">Invoices sent</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-2">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between ">
                <div>
                  <h4 className="fs-22 fw-semibold mb-2 ">
                    <span>$409.66k</span>
                  </h4>
                  <p className="text-uppercase fw-medium fs-14 text-muted mb-0">
                    PAID INVOICES
                    <span className="text-danger fs-14 mb-0 ms-1">
                      <SouthEastIcon
                        style={{
                          fontSize: "14px",
                          marginRight: "8px",
                          fill: "#ed4343",
                        }}
                      />
                      +8.09 %
                    </span>
                  </p>
                </div>
                <Avatar sx={{ bgcolor: "#F5F5F5" }}>
                  <i className="fa-solid fa-check text_primary"></i>
                </Avatar>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="badge bg-danger me-1">2,258</span>{" "}
                  <span className="text-muted">Invoices sent</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
          is_hide={true}
        />
      </div>
    </>
  );
};

export default Invoice;
