import { invokeApi } from "./invokeApi";

export const _add_payment_request_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/add_payment_request`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _payment_request_paid_or_canceled = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/payment_request_paid_or_canceled/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_payment_request_api = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/update_payment_request/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_payment_request_api = async (
  search_text,
  page,
  limit,
  type
) => {
  const requestObj = {
    path: `api/payment_request/list_payment_request?text=${search_text}&page=${page}&limit=${limit}&status=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_payment_request_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/get_payment_request/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_payment_request_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/delete_payment_request/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_payment_request_stripe_key_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/get_payment_request_stripe_key/${id}`,
    method: "GET",
  };
  return invokeApi(requestObj);
};

export const _convert_currency_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/convert_currency`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _pay_one_time_for_request_api = async (data) => {
  const requestObj = {
    path: `api/payments/pay_one_time_for_request`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _subcription_by_stripe_api = async (data) => {
  const requestObj = {
    path: `api/payments/subcription_by_stripe`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _confirm_subscription_incomplete_api = async (data) => {
  const requestObj = {
    path: `api/payments/confirm_subscription_incomplete`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _confirm_one_time_payment_api = async (data) => {
  const requestObj = {
    path: `api/payments/chnage_one_time_payment`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _payment_report_api = async (data, load_more) => {
  const requestObj = {
    path: load_more,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _send_invoice_reminder_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/send_invoice_reminder/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
