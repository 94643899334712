import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import { TRANSACTION_STATUS } from "../../utils/constant";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import { _list_transaction_api } from "../../DAL/Transaction/Transaction";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
// import { s3BaseUrl } from "../../config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const TransactionList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userList, setUserList] = useState([
    {
      _id: "65df2c126af1c9379f67e32d",
      transaction_amount: 234,
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis voluptatem quos sunt deserunt voluptates quas necessitatibus ipsa facere voluptatum vitae dolorem aliquid laudantium quibusdam reiciendis tenetur numquam, tempora iure asperiores?",
      status: true,
      attachment_url: "transaction/f1ca0cc0-d637-11ee-ace5-71b9700d53ef.jpg",
      createdAt: "2024-02-28",
      updatedAt: "2024-02-28",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65df2bc35615e73642a0d255",
      transaction_amount: 242,
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis voluptatem quos sunt deserunt voluptates quas necessitatibus ipsa facere voluptatum vitae dolorem aliquid laudantium quibusdam reiciendis tenetur numquam, tempora iure asperiores?",
      status: false,
      attachment_url: "",
      createdAt: "2024-02-28",
      updatedAt: "2024-02-28",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65df28aa50521a2ace3b45a0",
      transaction_amount: 231,
      description: "aioshfs",
      status: false,
      attachment_url: "transaction/ea6c9760-d635-11ee-bd18-6fd816a921fa.png",
      createdAt: "2024-02-28",
      updatedAt: "2024-02-28T12:35:54.971Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65df28aa50521a2ace3b459d",
      transaction_amount: 231,
      description: "aioshfs",
      status: false,
      attachment_url: "transaction/ea40a560-d635-11ee-bd18-6fd816a921fa.png",
      createdAt: "2024-02-28",
      updatedAt: "2024-02-28T12:35:54.681Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65df28a950521a2ace3b459a",
      transaction_amount: 231,
      description: "aioshfs",
      status: false,
      attachment_url: "transaction/e9ce5b90-d635-11ee-bd18-6fd816a921fa.png",
      createdAt: "2024-02-28",
      updatedAt: "2024-02-28T12:35:53.933Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65d4ae29449304b6cd1eb88a",
      transaction_amount: 1111,
      description: "kj",
      status: true,
      attachment_url: "",
      createdAt: "2024-02-20",
      updatedAt: "2024-02-20T13:50:33.986Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65d4adcba38aebb67dbc66d5",
      transaction_amount: 1111,
      description: "kj",
      status: true,
      attachment_url: "",
      createdAt: "2024-02-20",
      updatedAt: "2024-02-20T13:48:59.058Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
    {
      _id: "65d4ad1235b4bab636bdb1ec",
      transaction_amount: 8777,
      description: "TRANSACTION Test",
      status: true,
      attachment_url: "",
      createdAt: "2024-02-20",
      updatedAt: "2024-02-20T13:45:54.453Z",
      payment_made_by: "Stripe",
      payment_mode: "sandBox",
      payment_status: "succeeded",
      name: "Mayra Kub (Mayra@yahoo.com)",
    },
  ]);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDelete(false);
  };

  const get_list_transaction = async () => {
    // setIsLoading(true);
    // const result = await _list_transaction_api(page, rowsPerPage);
    // if (result.code === 200) {
    //   setUserList(
    //     result.transaction.map((item) => {
    //       let created_At = "N/A";
    //       if (item.createdAt) {
    //         created_At = moment(item.createdAt).format("DD-MM-YYYY");
    //       }
    //       return {
    //         ...item,
    //         created_At,
    // payment_made_by: "Stripe",
    // payment_mode: "sandBox",
    // payment_status: "succeeded",
    // name: "Mayra Kub (Mayra@yahoo.com)",
    //       };
    //     })
    //   );
    //   setTotalCount(result.count);
    //   setTotalPages(result.total_pages);
    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  const handleClickNavigate = () => {
    navigate(`/transactions/add-transaction`);
  };

  // const downloadImage = async (imageUrl) => {
  //   if (imageUrl) {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "image.png";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "name",
      label: "Member",
    },
    {
      id: "transaction_amount",
      label: "Amount",
      renderData: (row) => {
        return <span>{`$${row.transaction_amount}`}</span>;
      },
    },
    { id: "createdAt", label: "Transaction Date" },
    {
      id: "payment_made_by",
      label: "Transaction Made By",
    },
    {
      id: "payment_mode",
      label: "Transaction Mode",
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.payment_status
        );
        return (
          <span className={`${find_status?.class}`}>{find_status?.name}</span>
        );
      },
    },
    // {
    //   id: "attachment_url",
    //   label: "Attachment",
    //   renderData: (row) => {
    //     return (
    //       <>
    //         {row.attachment_url ? (
    //           <button
    //             className="btn btn-soft-info"
    //             onClick={() => downloadImage(s3BaseUrl + row.attachment_url)}
    //           >
    //             <i className="fa-solid fa-download fs-14"></i>
    //           </button>
    //         ) : (
    //           <spna>N/A</spna>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const handleView = () => {
    // navigate(``);
  };

  const handleAgreeDelete = () => {
    setOpenDelete(true);
  };

  const handleEdit = () => {
    navigate(`/transactions/edit-transaction/65e186cec9c7cdeccd2c0f8c`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  // useEffect(() => {
  //   get_list_transaction();
  // }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this transaction ?"}
        handleAgree={handleDelete}
      />

      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="page-title-box">
          <h4>Transaction</h4>
        </div>
        <div className="d-flex align-items-center ">
          <Button
            onClick={handleClickNavigate}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add Transaction
          </Button>
          {/* <div className="position-relative search_input_table dashboard_input ms-auto me-2 mt-lg-0 mt-3">
          <input
            type="text"
            className="form-control ps-3"
            placeholder="Search..."
            id="search-options"
          />
          <i className="fa-solid fa-magnifying-glass search_icon"></i>
        </div> */}
          {/* <button className="btn btn-soft-info ms-3 " onClick={handleClick}>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </button> */}
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          // custom_pagination={{
          //   total_count: totalCount,
          //   rows_per_page: rowsPerPage,
          //   page: page,
          //   handleChangePage: handleChangePage,
          //   onRowsPerPageChange: handleChangeRowsPerPage,
          // }}
          // pageCount={pageCount}
          // totalPages={totalPages}
          // handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
            mt: 1.5,
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          All
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Last Week
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Last Month
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Last Year
        </MenuItem>
      </Menu>
    </>
  );
};

export default TransactionList;
