import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import { _list_customer_api } from "../../DAL/User/User";
import dayjs from "dayjs";
import moment from "moment";

const UserFilter = ({
  inputs,
  handleSelectOther,
  handleSearch,
  handleClearFilter,
}) => {
  const [personName, setPersonName] = useState([]);
  const [searchText, setSearchText] = useState("");
  const maxDate = moment().format("YYYY-MM-DD");

  const get_user_list = async () => {
    const result = await _list_customer_api(searchText, 0, 50);
    if (result.code === 200) {
      let data = result.customers.customer_list.map((item) => {
        let full_name = "N/A";
        if (item.first_name) {
          full_name =
            item.first_name +
            " " +
            item.last_name +
            " (" +
            item.user_id.email +
            ")";
        }
        return {
          ...item,
          full_name,
          chip_label: full_name,
          chip_value: item._id,
        };
      });
      setPersonName(data);
    }
  };

  const handleSearchFilter = (e) => {
    e.preventDefault();
    handleSearch();
  };

  useEffect(() => {
    get_user_list();
  }, [searchText]);

  return (
    <div className="container">
      <form className="row mt-4" onSubmit={handleSearchFilter}>
        <div className="col-12">
          <MUIAutocomplete
            inputLabel="User"
            selectedOption={inputs.users}
            setSelectedOption={(e) => {
              handleSelectOther("users", e);
            }}
            optionsList={personName}
            setCustomSearch={setSearchText}
            name="full_name"
          />
        </div>

        <div className="col-12 mt-md-1">
          <FormControlLabel
            control={
              <Checkbox
                checked={inputs.filter_by_date}
                onChange={(e) => {
                  handleSelectOther("filter_by_date", e.target.checked);
                }}
              />
            }
            label="Filter by Date Range"
          />
        </div>

        {inputs?.filter_by_date && (
          <>
            <div className="col-12 mt-1">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  label="From"
                  name="start_date"
                  format="YYYY-MM-DD"
                  inputFormat="dd-MM-yyyy"
                  value={
                    inputs.start_date
                      ? dayjs(inputs.start_date)
                      : inputs.start_date
                  }
                  onChange={(e) => {
                    handleSelectOther("start_date", e.$d);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} className="mt-3 inputs-fields" />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 mt-3 mb-1">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  name="end_date"
                  format="YYYY-MM-DD"
                  inputFormat="dd-MM-yyyy"
                  value={
                    inputs.end_date ? dayjs(inputs.end_date) : inputs.end_date
                  }
                  onChange={(e) => {
                    handleSelectOther("end_date", e.$d);
                  }}
                  required
                  renderInput={(params) => (
                    <TextField {...params} className="mt-3 inputs-fields" />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}

        <div className="col-12 mt-md-0" style={{ marginTop: "-7px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={inputs.filter_by_amount}
                onChange={(e) => {
                  handleSelectOther("filter_by_amount", e.target.checked);
                }}
              />
            }
            label="Filter by Amount Range"
          />
        </div>

        {inputs.filter_by_amount && (
          <>
            <div className="col-12 col-md-6 mt-md-1">
              <TextField
                id="outlined-basic"
                label="Min Amount"
                placeholder="Min Amount"
                name="min_amount"
                variant="outlined"
                fullWidth
                required
                type="number"
                value={inputs.min_amount}
                onChange={(e) => {
                  handleSelectOther("min_amount", e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 mt-md-1 ">
              <TextField
                id="outlined-basic"
                label="Max Amount"
                placeholder="Max Amount"
                variant="outlined"
                fullWidth
                inputProps={{
                  min: +inputs.min_amount + 1,
                }}
                type="number"
                name="max_amount"
                required
                value={inputs.max_amount}
                onChange={(e) => {
                  handleSelectOther("max_amount", e.target.value);
                }}
              />
            </div>
          </>
        )}
        <div className="d-flex justify-content-end gap-3 mt-3 filter_btn">
          <Button
            type="submit"
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                  boxShadow: "none",
                }}
              />
            }
          >
            Filter
          </Button>
          <Button
            onClick={handleClearFilter}
            variant="outlined"
            startIcon={<ClearAllOutlinedIcon />}
          >
            Clear All
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserFilter;
