import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { s3BaseUrl } from "../../config/config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "../../assets";
import {
  _add_support_tickets_for_client_api,
  _get_list_department_api,
  _update_support_ticket_api,
  _upload_image_s3_api,
} from "../../DAL/SupportTicket";

const AddOrUpdateTicket = ({
  data,
  onCloseDrawer,
  editData,
  get_support_tickets_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [oldImagesArray, setOldImagesArray] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [files, setFiles] = useState([]);
  const [member, setMember] = useState(null);

  const [inputs, setInputs] = useState({
    department: "",
    subject: "",
    description: "",
    ticket_images: [],
    ticket_id: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleImageChange = (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const arr = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };
      arr.push(setImageObject);
    }
    setFiles((prevFiles) => [...prevFiles, ...arr]);
  };

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  const getCommentImage = (file) => {
    if (file.type === "file") {
      const ext = file.path.name.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return URL.createObjectURL(file.path);
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    } else {
      const ext = file.thumbnail_2.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return s3BaseUrl + file.thumbnail_2;
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    }
  };

  const handleRemove = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };
  const get_department_list = async () => {
    const result = await _get_list_department_api();
    if (result.code === 200) {
      setDepartmentList(result.department);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputs?.description) {
      enqueueSnackbar("Description is required", { variant: "error" });
      return;
    }

    setIsLoading(true);

    const results = files?.map((image, index) => {
      const formData = new FormData();
      formData.append("image", image.path);
      const result = _upload_image_s3_api(formData);
      return result;
    });

    Promise.all(results).then(async (img_results) => {
      let setImageArray = [];
      img_results?.map((image_path, index) => {
        setImageArray.push(image_path.path);
      });

      const formDataObject = {
        subject: inputs?.subject,
        description: inputs?.description,
        department: member?._id,
      };
      if (files.length > 0) {
        formDataObject.ticket_images = setImageArray;
      }
      const result = await _add_support_tickets_for_client_api(formDataObject);
      if (result.code === 200) {
        get_support_tickets_list();
        onCloseDrawer();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!inputs?.description) {
      enqueueSnackbar("Description is required", { variant: "error" });
      return;
    }

    setIsLoading(true);
    const deleted_images_array = [];
    let deleted_images_object = {};
    oldImagesArray.map((old_image, index) => {
      const filtered_array = files.filter(
        (image, index) => image.thumbnail_1 == old_image.thumbnail_1
      );
      delete old_image.type;

      if (filtered_array.length == 0) {
        deleted_images_array.push(old_image);
      }
    });
    if (deleted_images_array.length > 0) {
      deleted_images_object = {
        moment_image: deleted_images_array,
      };
    }

    const results = files.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = _upload_image_s3_api(formData);
        return result;
      } else {
        return image;
      }
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results.map((image_path, index) => {
        if (image_path.path) {
          setImageArray.push(image_path.path);
        } else {
          setImageArray.push(image_path.thumbnail_1);
        }
      });

      const formDataObject = {
        subject: inputs.subject,
        description: inputs.description,
        department: member?._id,
        status: true,
      };
      if (files.length > 0) {
        formDataObject.ticket_images = setImageArray;
      }
      const result = await _update_support_ticket_api(
        formDataObject,
        inputs.ticket_id
      );
      if (result.code === 200) {
        get_support_tickets_list();
        onCloseDrawer();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (data) {
      setInputs((prevState) => ({
        ...prevState,
        ["subject"]: editData.subject,
        ["description"]: editData.description,
        ["ticket_id"]: editData._id,
      }));
      setMember(editData.department);
      let setImageObject = {};
      editData.ticket_images?.map((images, index) => {
        setImageObject = {
          thumbnail_1: images,
          thumbnail_2: images,
          type: "image",
        };
        files.push(setImageObject);
        oldImagesArray.push(setImageObject);
      });
    }
  }, [data]);

  useEffect(() => {
    get_department_list();
  }, []);

  return (
    <form onSubmit={data ? handleUpdate : handleSubmit}>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Ticket Subject"
              placeholder="Ticket Subject"
              variant="outlined"
              fullWidth
              required
              name="subject"
              value={inputs.subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Departments"
              required={true}
              selectedOption={member}
              setSelectedOption={setMember}
              optionsList={departmentList}
              name="name"
            />
          </div>
          <div className="col-12 mt-3 full">
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={5}
              name="description"
              value={inputs.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 mt-3">
            <div className="w-100 px-3 d-flex">
              <div className="row w-100 mb-3 add-photo">
                <p className="mt-2">Recommended Size (1000x670)</p>
                {files &&
                  files.map((file, index) => (
                    <div className="col-3 mt-2">
                      <span className="preview">
                        <span
                          onClick={() => {
                            handleRemove(index);
                          }}
                        >
                          x
                        </span>
                        <img src={getCommentImage(file)} />
                      </span>
                    </div>
                  ))}
                <div className={`col-3 mt-2 ${files.length > 0 ? "ms-4" : ""}`}>
                  <span className="upload-button2">
                    <input
                      color="primary"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                      multiple={true}
                      accept="image/*,.pdf,.xlsx,.xls,.docx,.csv,.doc"
                    />
                    <label htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-end gap-3 mt-3">
            <Button type="submit" variant="contained">
              {data
                ? `${isLoading ? "Loading.." : " Update"}`
                : `${isLoading ? "Loading.." : "Save"}`}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateTicket;
