import React from "react";
import PhoneInput from "react-phone-input-2";

export const CustomPhoneInput = ({
  setInputsState,
  inputState,
  phoneNumber,
  inputClass,
  dropdownClass,
  required,
}) => {
  const handleChangePhone = (phone) => {
    setInputsState({ ...inputState, contact_number: phone });
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {required ? (
          <>
            <label htmlFor="phone" className="label_text">
              Phone Number *
            </label>
            <label htmlFor="phone" className="label_bg">
              Phone Number **
            </label>
          </>
        ) : null}

        <PhoneInput
          country={"GB"}
          inputClass={inputClass}
          dropdownClass={dropdownClass}
          value={phoneNumber}
          enableSearch
          disableSearchIcon
          countryCodeEditable={false}
          onChange={handleChangePhone}
          buttonStyle={{
            border: "none",
            backgroundColor: "transparent",
          }}
          inputProps={{
            name: "phone",
            required: required ? true : false,
            autoComplete: "off",
          }}
        />

        {/* <PhoneInput
          country={"GB"}
          inputClass={inputClass}
          dropdownClass={dropdownClass}
          value={phoneNumber}
          enableSearch
          disableSearchIcon
          countryCodeEditable={false}
          onChange={handleChangePhone}
          buttonStyle={{
            border: "none",
            backgroundColor: "transparent",
          }}
          inputProps={{
            name: "phone",
            required: required ? true : false,
            autoComplete: "off",
          }}
        /> */}
      </div>
      {/* <PhoneInput
        country={"GB"}
        inputClass={inputClass}
        dropdownClass={dropdownClass}
        value={phoneNumber}
        enableSearch
        disableSearchIcon
        countryCodeEditable={false}
        onChange={handleChangePhone}
        buttonStyle={{
          border: "none",
          backgroundColor: "transparent",
        }}
        inputProps={{
          name: "phone",
          required: required ? true : false,
          autoComplete: "off",
        }}
      /> */}
    </>
  );
};
