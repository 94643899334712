import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { CustomPhoneInput } from "../components/CustomPhoneInput";
import { useSnackbar } from "notistack";
import { _signup_admin_api } from "../DAL/Login/Login";
import { useAdminContext } from "../Hooks/AdminContext";
import {
  _set_token_in_localStorage,
  _set_user_in_localStorage,
} from "../local_storage/local_storage";
import { s3BaseUrl } from "../config/config";
import { countries } from "../utils/country";

const Signup = () => {
  const navigate = useNavigate();
  const { adminSettingList } = useAdminContext();
  const { setUserInfo } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("44");

  const general_setting = adminSettingList.gerenal_settings;
  const signup_page_settings = adminSettingList?.signup_page_settings;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    address: "",
    password: "",
    status: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      password: inputs.password,
      contact_number: inputs.contact_number,
      country: country ? country.code : "",
    };
    const result = await _signup_admin_api(postData);
    if (result.code === 200) {
      setUserInfo(result.business);
      _set_token_in_localStorage(result.token);
      _set_user_in_localStorage(result.business);
      navigate(`/dashboard`, { replace: true });
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="account-pages">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-11">
              <div className="d-flex py-lg-2 py-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    {general_setting.admin_logo && (
                      <div className="text-center mb-5">
                        <img
                          src={s3BaseUrl + general_setting.admin_logo}
                          className="img-fluid "
                        />
                      </div>
                    )}
                    <div className="my-auto overflow-hidden card">
                      <div className="row g-0">
                        <div className="col-lg-6">
                          <form onSubmit={handleSubmit}>
                            <div className="p-lg-5 p-4 card-body">
                              <div className="text-center">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: signup_page_settings?.welcome_text,
                                  }}
                                ></div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 mt-4">
                                  <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="First Name"
                                    required
                                    name="first_name"
                                    value={inputs.first_name}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 col-md-6 mt-4">
                                  <TextField
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Last Name"
                                    required
                                    name="last_name"
                                    value={inputs.last_name}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 mt-4">
                                  <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Email"
                                    required
                                    name="email"
                                    value={inputs.email}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-12 mt-4">
                                  <CustomPhoneInput
                                    required={true}
                                    inputState={inputs}
                                    setInputsState={setInputs}
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                  />
                                </div>
                                <div className="col-12  mt-4">
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      Password *
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-password"
                                      type={showPassword ? "text" : "password"}
                                      placeholder="Password"
                                      fullWidth
                                      required
                                      name="password"
                                      value={inputs.password}
                                      onChange={handleChange}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff
                                                style={{
                                                  fontSize: "18px",
                                                }}
                                              />
                                            ) : (
                                              <Visibility
                                                style={{
                                                  fontSize: "18px",
                                                }}
                                              />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Password"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-12 mt-4">
                                <Autocomplete
                                  id="country-select-demo"
                                  options={countries}
                                  autoHighlight
                                  value={country ? country : null}
                                  onChange={(event, newValue) => {
                                    setCountry(newValue);
                                  }}
                                  getOptionLabel={(option) => option.label}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      {option.label} ({option.code})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      required
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="mt-2">
                                {signup_page_settings?.agree_terms_of_use &&
                                  signup_page_settings?.agree_terms_of_use && (
                                    <div className="fs-16 pb-2">
                                      <p className="mb-0 fs-14 text-muted fst-italic">
                                        {
                                          signup_page_settings?.agree_terms_of_use
                                        }
                                        <a className="text_primary text-decoration-underline fst-normal fw-medium">
                                          {
                                            signup_page_settings?.agree_terms_of_use_link
                                          }
                                        </a>
                                      </p>
                                    </div>
                                  )}
                              </div>
                              <div className="mt-2">
                                <button
                                  className="btn btn-primary w-100"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Loading..." : "Sign Up"}
                                </button>
                              </div>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  {signup_page_settings?.have_an_account_text}{" "}
                                  <a
                                    className="fw-medium text_primary text-decoration-underline pointer"
                                    onClick={() => navigate(`/Login`)}
                                  >
                                    {
                                      signup_page_settings?.have_an_account_link_text
                                    }
                                  </a>
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          className="col-lg-6 overlay-container"
                          style={{
                            backgroundImage: `url(${
                              s3BaseUrl + signup_page_settings?.slider_bg_img
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <div className="div-img-overlay"></div>
                          {signup_page_settings?.slider_text_1 &&
                            signup_page_settings?.slider_text_2 &&
                            signup_page_settings?.slider_text_3 && (
                              <div
                                id="carouselExampleCaptions"
                                className="carousel slide h-100"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-indicators">
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={0}
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={1}
                                    aria-label="Slide 2"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={2}
                                    aria-label="Slide 3"
                                  />
                                </div>
                                <div className="carousel-inner ">
                                  <div className="carousel-item active ">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            signup_page_settings?.slider_text_1,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            signup_page_settings?.slider_text_2,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5 ">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            signup_page_settings?.slider_text_3,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        {general_setting.copyright_text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
