import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTable from "../../components/customTable/CustomTable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Payments = ({
  paymentList,
  TABLE_HEAD,
  isTabLoading,
  total_count,
  rows_per_page,
  page,
  handleChangePage,
  onRowsPerPageChange,
  searchText,
  setSearchText,
  handleSubmit,
  pageCount,
  totalPages,
  handleChangePages,
}) => {
  const classes = useStyles();

  if (isTabLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomTable
        data={paymentList}
        TABLE_HEAD={TABLE_HEAD}
        custom_pagination={{
          total_count: total_count,
          rows_per_page: rows_per_page,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        custom_search={{
          searchText: searchText,
          setSearchText: setSearchText,
          handleSubmit: handleSubmit,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        pagePagination={true}
      />
    </>
  );
};

export default Payments;
