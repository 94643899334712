import React from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function FullPagePopupForTitle({
  open,
  handleClosePopup,
  componentToPassDown,
  title,
}) {
  const handleClose = () => {
    handleClosePopup();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
          },
        }}
        disableEnforceFocus={true}
      >
        <div className="pt-3 ps-3 d-flex">
          <IconButton onClick={handleClose}>
            <CloseIcon />{" "}
          </IconButton>
          <span>
            <h4 className="anchor-style ms-2" style={{ marginTop: "11px" }}>
              {title ? title : ""}
            </h4>
          </span>
        </div>
        <div>{componentToPassDown}</div>
      </Dialog>
    </div>
  );
}
