import React, { useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_user_api, _edit_user_api } from "../../DAL/User/User";
import countryList from "react-select-country-list";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import { _add_taxes_api } from "../../DAL/Taxes/Taxes";
import { countries } from "../../utils/country";

const AddOrUpdateTaxes = ({ setOpen, get_taxes_list }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });
  const [paymentType, setPaymentType] = useState([
    { option: "Northeast" },
    { option: "Midwest" },
    { option: "South" },
    { option: "West" },
  ]);

  const options = useMemo(() => countryList().getData(), []);
  const [inputs, setInputs] = useState({
    tax_name: "",
    country: "",
    region: "",
    tax_rate: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(false);

    // setIsLoading(true);
    // const formData = new FormData();
    // formData.append("tax_name", inputs.tax_name);
    // formData.append("country", country ? country.label : "");
    // formData.append("region", inputs.region?.option);
    // formData.append("tax_rate", inputs.tax_rate);
    // const result = await _add_taxes_api(formData);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setOpen(false);
    //   get_taxes_list();
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tax Name"
            variant="outlined"
            fullWidth
            placeholder="Tax Name*"
            required
            name="tax_name"
            value={inputs.tax_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-6 mt-4">
          <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            name="country"
            value={country ? country : null}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
          {/* <MUIAutocomplete
            inputLabel="Country"
            optionsList={options}
            name="label"
            required
            value={inputs.country}
            setSelectedOption={(e) => handleChangeOthers("country", e)}
          /> */}
        </div>
        <div className="col-6 mt-4">
          <MUIAutocomplete
            inputLabel="Region"
            optionsList={paymentType}
            name="option"
            required
            value={inputs.region}
            setSelectedOption={(e) => handleChangeOthers("region", e)}
          />
        </div>
        <div className="col-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Tax Rate"
            variant="outlined"
            fullWidth
            placeholder="Tax Rate *"
            name="tax_rate"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { max: 100 },
            }}
            required
            value={inputs.tax_rate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end gap-3 mt-3">
        <div className="btn btn-light" onClick={handleClose}>
          Close
        </div>
        <button className="btn btn-primary" disabled={isLoading}>
          {isLoading ? "Loading..." : "Add Taxes"}
        </button>
      </div>
    </form>
  );
};

export default AddOrUpdateTaxes;
