import React, { useEffect, useState } from "react";
import {
  PAYMENT_STATUS,
  convertCurrencyToSign,
  show_proper_words,
} from "../../utils/constant";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import {
  _delete_payment_request_api,
  _list_payment_request_api,
  _payment_request_paid_or_canceled,
} from "../../DAL/Payment";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Business_Portal_URL, s3BaseUrl } from "../../config/config";
import CustomPopover from "../../components/CustomPopover";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Payments from "./Payments";
import CustomTabs from "../../components/CustomTabs";
import CustomConfirmation from "../../components/CustomConfirmation";
import PaymentRequestPaidOrCanceled from "./PaymentRequestPaidOrCanceled";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "10%",
  },
}));

const status_object = ["all", "paid", "pending", "canceled"];

const Invoices = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [type, setType] = useState("paid");

  const handleChange = (event, newValue) => {
    setIsTabLoading(true);
    setPage(0);
    setPageCount(1);
    setTabValue(newValue);
    setSearchText("");
    localStorage.removeItem("payment_search_text");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_payment_list = async () => {
    setIsTabLoading(true);
    let search_keyword =
      localStorage.getItem("payment_search_text") == null
        ? searchText
        : localStorage.getItem("payment_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    let type = status_object[tabValue];
    const result = await _list_payment_request_api(
      search_keyword,
      page,
      rowsPerPage,
      type
    );
    console.log("tabValue  __tabValue", tabValue);
    console.log("_list_payment_request_api  __tabValue", result);
    if (result.code === 200) {
      let data = result?.payment_request_list.map((request) => {
        let client_name = "N/A";
        let date = "N/A";
        if (request?.client_info) {
          client_name =
            request.client_info.first_name +
            " " +
            request.client_info.last_name +
            " (" +
            request.client_info.email +
            ")";
        }

        if (request.createdAt) {
          date = moment(request.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...request,
          client_name,
          date,
          MENU_OPTIONS: handleMenu(request),
        };
      });
      setPaymentList(data);
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_payment_list();
  };

  const handleClickOpen = () => {
    navigate(`/invoices/add-invoice`);
  };

  const handleEdit = (value) => {
    navigate(`/invoices/edit-invoice/${value._id}`);
  };

  const handleOpenPopoverPaid = (value) => {
    setType("paid");
    setRowData(value);
    setOpenPopover(true);
  };
  const handleOpenPopoverCancel = (value) => {
    setType("cancel");
    setRowData(value);
    setOpenPopover(true);
  };

  const handleView = (value) => {
    navigate(`/invoices/invoice-detail/${value._id}`);
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Invoice URL Copied to clipboard", { variant: "success" });
  };

  const handleCratePayment = (value) => {
    setOpen(true);
    setInvoiceId(s3BaseUrl + value.invoice);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteId(value._id);
  };

  const updateListObject = () => {
    setIsLoading(true);

    let type = status_object[tabValue];
    // const status_object = ["all", "paid", "pending", "canceled"];
    if (type == "all") {
      get_payment_list();
      // let currentDate = moment();
      // const newList = paymentList.map((item) => {
      //   if (item._id === rowData?._id) {
      //     return {
      //       ...item,
      //       payment_status: "canceled",
      //       canceled_date: currentDate,
      //     };
      //   }
      //   return item;
      // });
      // setPaymentList(newList);
    } else {
      let user_list = paymentList.filter(
        (user_id) => user_id._id !== rowData?._id
      );
      setPaymentList(user_list);
    }

    setIsLoading(false);
  };

  const handleSubmitCanceledInvoice = async () => {
    let postData = {
      mark_as_paid: false,
      mark_as_canceled: true,
    };

    const result = await _payment_request_paid_or_canceled(
      postData,
      rowData?._id
    );

    if (result.code === 200) {
      updateListObject();
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenPopover(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_payment_request_api(deleteId);
    if (result.code == 200) {
      let user_list = paymentList.filter((user_id) => user_id._id !== deleteId);
      setPaymentList(user_list);
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
      setDelLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setDelLoading(false);
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "client_name",
      label: "Name",
      renderData: (row) => {
        return (
          <span
            className="pointer"
            onClick={() => {
              handleView(row);
            }}
          >
            {row.client_name}
          </span>
        );
      },
    },
    { id: "request_title", label: "Request Title" },
    {
      id: "request_type",
      label: "Payment Type",
      renderData: (row) => {
        return <span>{show_proper_words(row.request_type)}</span>;
      },
    },
    {
      id: "total_amount",
      label: "Total Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) + (row.total_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "initial_amount",
      label: "Initial Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) + (row.initial_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "installment_amount",
      label: "Installment Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) +
              (row.installment_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "no_of_installment",
      label: "No of Installment",
      renderData: (row) => {
        return <span>{row.no_of_installment ?? 0}</span>;
      },
    },
    {
      id: "invoice",
      label: "Invoice PDF",
      renderData: (row) => {
        return (
          <>
            {row.invoice ? (
              <a
                className="pointer"
                onClick={() => {
                  handleCratePayment(row);
                }}
              >
                Preview
              </a>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
    {
      id: "invoice",
      label: "Invoice URL",
      renderData: (row) => {
        return (
          <>
            {row.invoice ? (
              <CopyToClipboard
                className="pointer me-2"
                text={`${Business_Portal_URL}create-payment/${row._id}`}
                onCopy={() => handleCopiedUrl()}
              >
                <Tooltip title="Copy Invoice URL">
                  <ContentCopyIcon style={{ fontSize: "20px" }} />
                </Tooltip>
              </CopyToClipboard>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
    { id: "date", label: "Created At" },
    {
      id: "is_basic_membership",
      label: "Is Basic Membership",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row?.is_basic_membership === false ? "error" : "success"}
          >
            {row?.is_basic_membership === true
              ? `Membership Days ${row?.basic_membership_days}`
              : "No"}
          </Label>
        );
      },
    },
    {
      id: "is_first_paid",
      label: "First Paid",
      renderData: (row) => {
        let textRender = "Paid";
        let colorRender = "success";

        // paid_date

        if (row?.payment_status === "canceled") {
          let canceledDate = moment(row?.canceled_date).format("DD-MM-YYYY");
          textRender = `Canceled on ${canceledDate}`;
          colorRender = "error";
        } else {
          if (!row.is_first_paid) {
            textRender = "Pending";
            colorRender = "error";
          }

          if (row?.payment_status === "paid") {
            let paidDate = moment(row?.paid_date).format("DD-MM-YYYY");
            textRender = `Paid on ${paidDate}`;
            colorRender = "success";
          }
        }

        return (
          <Label variant="ghost" color={colorRender}>
            {textRender}
          </Label>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            {!!find_status && (
              <span className={`${find_status?.class}`}>
                {find_status?.name}
              </span>
            )}
          </>
        );
      },
    },
  ];

  const handleMenu = (row) => {
    console.log("row  ___row", row);
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
      {
        label: "View Detail",
        icon: "ep:view",
        handleClick: handleView,
      },
    ];
    if (row.is_first_paid === false) {
      MENU_OPTIONS.splice(0, 0, {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
    }

    if (row?.payment_status === "pending") {
      if (row?.request_type === "onetime") {
        MENU_OPTIONS.push({
          label: "Mark as Paid",
          icon: "grommet-icons:status-good",
          handleClick: handleOpenPopoverPaid,
        });

        MENU_OPTIONS.push({
          label: "Mark as Cancel",
          icon: "material-symbols:cancel-outline",
          handleClick: handleOpenPopoverCancel,
        });
      }

      if (row?.request_type === "recurring") {
        MENU_OPTIONS.push({
          label: "Mark as Cancel",
          icon: "material-symbols:cancel-outline",
          handleClick: handleOpenPopoverCancel,
        });
      }
    }

    return MENU_OPTIONS;
  };

  const PaymentsComponent = (
    <div style={{ minHeight: "300px" }}>
      <Payments
        paymentList={paymentList}
        TABLE_HEAD={TABLE_HEAD}
        isTabLoading={isTabLoading}
        total_count={totalCount}
        rows_per_page={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSubmit={searchFunction}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
      />
    </div>
  );

  const TABS_OPTIONS = [
    {
      title: "All",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
    {
      title: "Paid",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
    {
      title: "Pending",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },

    {
      title: "Canceled",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
  ];

  useEffect(() => {
    get_payment_list();
  }, [rowsPerPage, page, tabValue]);

  useEffect(() => {
    localStorage.setItem("payment_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("payment_search_text", "");
    } else {
      localStorage.setItem("payment_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this invoice ?"}
        handleAgree={handleDelete}
      />
      <div className="d-lg-flex justify-content-between flex-wrap align-items-center">
        <div className="page-title-box">
          <h4>Invoices</h4>
        </div>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          startIcon={
            <AddIcon
              style={{
                fill: "#fff",
              }}
            />
          }
        >
          Add Invoice
        </Button>
      </div>
      <div className="card mt-3">
        <CustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
      <CustomPopover
        isOpenPop={open}
        isClosePop={setOpen}
        title="Invoice PDF"
        width="600px"
        componentToPassDown={
          <>
            <iframe src={invoiceId} width="100%" height="500px" />
          </>
        }
      />
      <CustomConfirmation
        open={type !== "paid" ? openPopover : false}
        setOpen={setOpenPopover}
        title={"Are you sure you want to Cancel this Invoice?"}
        handleAgree={handleSubmitCanceledInvoice}
      />

      <CustomPopover
        isOpenPop={type === "paid" ? openPopover : false}
        isClosePop={setOpenPopover}
        title={rowData?.request_title}
        width="600px"
        componentToPassDown={
          <>
            <PaymentRequestPaidOrCanceled
              rowData={rowData}
              type={type}
              setOpenPopover={setOpenPopover}
              paymentList={paymentList}
              setPaymentList={setPaymentList}
              tabType={status_object[tabValue]}
              get_payment_list={get_payment_list}
            />
          </>
        }
      />
    </>
  );
};

export default Invoices;
