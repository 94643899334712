import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Avatar, Button, CircularProgress, Tooltip } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { _delete_customer_api, _list_customer_api } from "../../DAL/User/User";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { s3BaseUrl } from "../../config/config";
import { UserIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import ChangePasswordUser from "../../components/ChangePasswordUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Users = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    navigate(`/users/add-user`);
  };

  const handleClickEdit = (row) => {
    navigate(`/users/edit-user/${row.user_id._id}`);
  };

  const handleClickPassWord = (row) => {
    setopenPasswordModal(true);
    setUserId(row);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row.user_id._id);
    setOpenDelete(true);
  };

  const get_user_list = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("user_search_text") == null
        ? searchText
        : localStorage.getItem("user_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await _list_customer_api(search_keyword, page, rowsPerPage);
    if (result.code === 200) {
      let data = result.customers.customer_list.map((item) => {
        let full_name = "N/A";
        let user_email = "N/A";
        let created_At = "N/A";
        if (item.first_name) {
          full_name = item.first_name + " " + item.last_name;
        }
        if (item.user_id.email) {
          user_email = item.user_id.email;
        }
        if (item.createdAt) {
          created_At = moment(item.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...item,
          full_name,
          user_email,
          created_At,
        };
      });
      setUserList(data);
      setTotalCount(result.customers.count);
      setTotalPages(result.customers.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_user_list();
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_customer_api(deleteId);
    if (result.code === 200) {
      let user_list = userList.filter(
        (user_id) => user_id.user_id._id !== deleteId
      );
      setUserList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "full_name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="me-3 table_avatar">
                <Avatar
                  src={
                    row?.profile_image
                      ? s3BaseUrl + row?.profile_image
                      : UserIcon
                  }
                />
              </div>
              <span>{row.full_name}</span>
            </div>
          </>
        );
      },
    },
    { id: "user_email", label: "Email" },
    {
      id: "contact_number",
      label: "Phone Number",
      renderData: (row) => {
        return <span>{`+${row.contact_number}`}</span>;
      },
    },
    {
      id: "business_name",
      label: "Business Name",
      renderData: (row) => {
        return <span>{row.business_name ? row.business_name : "N/A"}</span>;
      },
    },
    { id: "created_At", label: "Created At" },
    {
      id: "action",
      label: "Action",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <Tooltip title="Change Password" placement="top">
                <button
                  className="btn btn-soft-info"
                  onClick={() => {
                    handleClickPassWord(row);
                  }}
                >
                  <i className="fa-solid fa-unlock"></i>
                </button>
              </Tooltip>
              <Tooltip title="Edit User" placement="top">
                <button
                  className="btn btn-soft-info"
                  onClick={() => {
                    handleClickEdit(row);
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete User" placement="top">
                <button
                  className="btn btn-soft-danger"
                  onClick={() => {
                    handleAgreeDelete(row);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_user_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("user_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("user_search_text", "");
    } else {
      localStorage.setItem("user_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this user ?"}
        handleAgree={handleDelete}
      />
      <ChangePasswordUser
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
        userId={userId}
      />

      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Users</h4>
        </div>
        <div>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add User
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default Users;
