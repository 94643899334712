import React from "react";
import { useAdminContext } from "../../Hooks/AdminContext";
import { s3BaseUrl } from "../../config/config";

const ThanksPage = () => {
  const { adminSettingList } = useAdminContext();
  const dataList = adminSettingList?.payment_thanks_settings;
  const gerenal_settings = adminSettingList?.general_settings;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center p-4">
            <img src={s3BaseUrl + gerenal_settings?.admin_logo} alt="" />
          </div>
          <div className="col-md-9 mx-auto mb-4">
            <div className="border border-3 border_success" />
            <div className="card p-5">
              <div className="mb-4 text-center">
                <img
                  src={s3BaseUrl + dataList?.thanks_img}
                  className="img-fluid"
                />
              </div>
              <div className="text-center ">
                <div className="thanks_para">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataList?.thanks_description,
                    }}
                  ></div>
                </div>

                {dataList?.button_text && (
                  <a
                    href={dataList?.button_link}
                    className="btn btn-primary mt-3"
                  >
                    {dataList?.button_text}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThanksPage;
