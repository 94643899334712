import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useSnackbar } from "notistack";
import { _add_transaction_api } from "../../DAL/Transaction/Transaction";
import { useNavigate } from "react-router-dom";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import { TRANSACTION_STATUS } from "../../utils/constant";

const AddorUpdatedTransaction = ({ value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState();
  const [serchText, setSerchText] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    transaction_amount: "",
    description: "",
    status: "",
    // attachment_url: {},
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setInputs({
  //     ...inputs,
  //     ["attachment_url"]: file,
  //   });
  //   setUploadedFile(file);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const file = e.dataTransfer.files[0];
  //   setUploadedFile(file);
  // };

  // const formatFileSize = (sizeInBytes) => {
  //   const sizeInKB = sizeInBytes / 1024;
  //   return sizeInKB.toFixed(2) + " KB";
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // const formData = new FormData();
    // formData.append("transaction_amount", inputs.transaction_amount);
    // formData.append("description", inputs.description);
    // formData.append("status", inputs.status);
    // // if (uploadedFile) {
    // //   formData.append("attachment_url", inputs.attachment_url);
    // // }

    // const result = await _add_transaction_api(formData);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   navigate(`/transactions`);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  let breadCrumbMenu = [
    {
      title: "Transactions",
      navigation: `/transactions`,
      active: false,
    },
    {
      title: value ? "Edit Transaction" : "Add Transaction",
      active: true,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="page-title-box">
          <h4>{value ? "Edit TRANSACTION" : "Add TRANSACTION"} </h4>
        </div>
        <div>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-6 mt-3">
                <MUIAutocomplete
                  inputLabel="Members"
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={personName}
                  setCustomSearch={setSerchText}
                  name="member_name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Amount"
                  value={inputs.transaction_amount}
                  name="transaction_amount"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.status}
                    label="Status"
                    name="status"
                    required
                    onChange={(e) => {
                      handleChangeOthers("status", e.target.value);
                    }}
                  >
                    {TRANSACTION_STATUS.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <TextareaAutosize
                  aria-label="empty textarea"
                  className="textarea-autosize"
                  placeholder="Description *"
                  value={inputs.description}
                  name="description"
                  onChange={handleChange}
                  required
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                    color: "#000",
                    borderRadius: "5px",
                    outline: "none",
                    paddingTop: "8px",
                    paddingLeft: "10px",
                    border: "1px solid #00000038",
                  }}
                />
              </div>

              {/* <div className="col-12 mt-3">
                <p className="mb-2 input_top_text">Attachment</p>
                <div
                  className="dropzone text-center"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <label
                    htmlFor="fileInput"
                    className="dz-message needsclick"
                    role="presentation"
                  >
                    <div className="mb-3 mt-3">
                      <i className="fa-solid fa-cloud-arrow-up display-4 text-muted"></i>
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                {uploadedFile && (
                  <div className="file_input_name mt-2">
                    <div className="p-2">
                      <div className="align-items-center row">
                        <a className="text-muted fw-bold">
                          {uploadedFile.name}
                        </a>
                        <p className="mb-0">
                          <strong>{formatFileSize(uploadedFile.size)}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
              <div className="col-12 mt-4">
                <div className="d-flex justify-content-end gap-3 mt-3">
                  <button className="btn btn-primary" disabled={isLoading}>
                    {value
                      ? `${isLoading ? "Loading.." : "Update"}`
                      : `${isLoading ? "Loading.." : "Add Transaction"}`}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddorUpdatedTransaction;
