import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { _change_password_user_api } from "../DAL/Login/Login";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function ChangePasswordUser({
  openPasswordModal,
  setopenPasswordModal,
  userId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    password: "",
    repeatPassword: "",
  });

  const handleClose = () => {
    setopenPasswordModal(false);
    setInputs({
      password: "",
      repeatPassword: "",
    });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (inputs.password !== inputs.repeatPassword) {
      enqueueSnackbar("Password And Confirm Password Password not matched", {
        variant: "error",
      });
      setIsLoading(false);
    } else {
      let postData = {
        new_password: inputs.password,
        confirm_password: inputs.repeatPassword,
      };
      const result = await _change_password_user_api(
        userId?.user_id?._id,
        postData
      );
      if (result.code === 200) {
        setInputs({});
        setIsLoading(false);
        handleClose();
        enqueueSnackbar("Password Changed Successfully", {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openPasswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlePasswordSubmit}>
          <Box sx={style} className="modal-theme">
            <div
              clo-12
              className="text-end modalIcon pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Change User Password
            </Typography>
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Password"
                name="password"
                variant="outlined"
                fullWidth
                required
                placeholder="Password"
                value={inputs.password}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Confirm Password"
                name="repeatPassword"
                variant="outlined"
                fullWidth
                required
                placeholder="Confirm Password"
                value={inputs.repeatPassword}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <button className="btn btn-primary w-100">
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
