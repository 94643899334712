import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomTabs from "../../components/CustomTabs";
import OpenTicket from "./components/OpenTicket";
import CustomDrawer from "../../components/CustomDrawer";
import AddOrUpdateTicket from "./AddOrUpdateTicket";
import ResolvedTicket from "./components/ResolvedTicket";
import {
  _delete_support_ticket_api,
  _list_support_tickets_for_client_api,
  _mark_resolve_support_ticket_api,
} from "../../DAL/SupportTicket";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import CustomConfirmation from "../../components/CustomConfirmation";
import AllTicket from "./components/AllTicket";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

let tab_value = ["all", "waiting", "answered"];

const SupportTicket = () => {
  const classes = useStyles();
  const { type } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [ticketList, setTicketList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [formData, setFormData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [tabValue, setTabValue] = useState(1);
  const [editData, setEditData] = useState({});
  const [totalCount, setTotalCount] = useState({});
  const [markLoadin, setMarkLoading] = useState(false);
  const [markId, setMarkId] = useState("");
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const handleChange = (event, newValue) => {
    navigate(`/support-tickets/${tab_value[newValue]}`);
    setTabValue(newValue);
  };
  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setDrawerOpen(true);
    setFormData(true);
  };

  const get_support_tickets_list = async (value) => {
    setIsLoading(true);
    let postData = { status: value };
    const result = await _list_support_tickets_for_client_api(postData);
    if (result.code === 200) {
      const data = result.support_tickets.map((item) => {
        return {
          ...item,
          MENU_OPTIONS: handleMenu(item),
        };
      });
      setTicketList(data);
      setTotalCount(result);
      setIsLoading(false);
      setIsFirstLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsFirstLoading(false);
    }
  };

  const handleClickOpen = () => {
    handleOpenDrawer();
    setFormData(false);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_support_ticket_api(deleteId);
    if (result.code === 200) {
      get_support_tickets_list();
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmitMark = async () => {
    setMarkLoading(true);
    const result = await _mark_resolve_support_ticket_api(markId);
    if (result.code === 200) {
      get_support_tickets_list();
      setOpen(false);
      setMarkLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setMarkLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleClickMark = (value) => {
    setOpen(true);
    setMarkId(value._id);
  };

  const handleClickDetail = (row) => {
    navigate(
      `/support-tickets/detail-ticket/${tab_value[tabValue]}/${row._id}`
    );
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = null;
    if (row.ticket_status === 1) {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: handleClickDetail,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        },
      ];
    } else {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: handleClickDetail,
        },
        {
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleEdit,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        },
        {
          label: "Mark Resolve",
          icon: "emojione-monotone:heavy-check-mark",
          handleClick: handleClickMark,
        },
      ];
    }
    return MENU_OPTIONS;
  };

  const TABS_OPTIONS = [
    {
      title: `All (${totalCount?.total_count ?? 0})`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <AllTicket
          ticketList={ticketList}
          handleClickDetail={handleClickDetail}
        />
      ),
    },
    {
      title: `Open (${totalCount?.waiting_count ?? 0})`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <OpenTicket
          ticketList={ticketList}
          handleClickDetail={handleClickDetail}
        />
      ),
    },
    {
      title: `Close (${totalCount?.close_count ?? 0})`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <ResolvedTicket
          ticketList={ticketList}
          handleClickDetail={handleClickDetail}
        />
      ),
    },
  ];

  useEffect(() => {
    let tab_type = "waiting";
    if (type) {
      tab_type = type;
      let find_index = tab_value.findIndex((item) => item == type);
      setTabValue(find_index);
    } else {
      setTabValue(1);
    }
    get_support_tickets_list(tab_type);
  }, [type]);

  if (isFirstLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this ticket ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={open}
        setOpen={setOpen}
        loading={markLoadin}
        title={"Are you sure you want to mark this ticket as resolved ?"}
        handleAgree={handleSubmitMark}
      />
      <div className="d-lg-flex justify-content-between flex-wrap align-items-center">
        <div className="page-title-box">
          <h4>Support Ticket</h4>
        </div>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          startIcon={
            <AddIcon
              style={{
                fill: "#fff",
              }}
            />
          }
        >
          New Ticket
        </Button>
      </div>
      <div className="card mt-3">
        <CustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={drawerOpen}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formData ? "Edit Support Ticket" : "New Ticket"}`}
        componentToPassDown={
          <AddOrUpdateTicket
            data={formData}
            editData={editData}
            get_support_tickets_list={get_support_tickets_list}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </>
  );
};

export default SupportTicket;
