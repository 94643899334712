import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { convertCurrencyToSign, show_proper_words } from "../../utils/constant";
import { useSnackbar } from "notistack";
import {
  _confirm_one_time_payment_api,
  _confirm_subscription_incomplete_api,
  _convert_currency_api,
  _pay_one_time_for_request_api,
  _subcription_by_stripe_api,
} from "../../DAL/Payment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { s3BaseUrl } from "../../config/config";

const PaymentPage = ({}) => {
  const { adminSettingList } = useAdminContext();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements({});
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValue, setSelectedValue] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changeCurrency, setChangeCurrency] = useState(false);
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");

  const result = adminSettingList?.payment_request;
  const gerenal_settings = adminSettingList?.general_settings;
  const request_id = result?._id;

  const handleChangeCheck = (value) => {
    setErrorMessage("");
    if (value == "stripe") {
      setAmount(result?.total_amount);
      setCurrency(result?.currency);
      setSelectedValue(true);
    } else {
      setSelectedValue(false);
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleChangeCurrency = async (e) => {
    const { value } = e.target;
    setCurrency(value);
    setChangeCurrency(true);
    let postData = {
      amount: amount,
      payable_currency: value,
      request_id: request_id,
    };

    const result = await _convert_currency_api(postData);
    if (result.code === 200) {
      setAmount(result.data.toFixed());
      setChangeCurrency(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setChangeCurrency(false);
    }
  };

  const confirmCardPayment = async () => {
    let postData = {
      request_id: request_id,
    };
    const result = await _confirm_one_time_payment_api(postData);
    if (result.code === 200) {
      navigate(`/thanks/${params.id}`);
      setIsLoading(false);
      enqueueSnackbar(result.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const confirmPayment = async (subscription_id) => {
    let postData = {
      subscription_id: subscription_id,
    };
    const result = await _confirm_subscription_incomplete_api(postData);
    if (result.code === 200) {
      navigate(`/thanks/${params.id}`);
      setIsLoading(false);
      enqueueSnackbar(result.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSecureCard = (client_secret, cardElement) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });
    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        confirmCardPayment();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCard = (client_secret, cardElement) => {
    stripe
      .confirmCardPayment(client_secret.client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        confirmPayment(client_secret.subscription_id);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const pay_one_time_for_request = async (cardElement) => {
    let postData = {
      payment_method: "stripe",
      request_id: request_id,
    };
    if (!selectedValue) {
      postData.payment_method = "bank";
      postData.currency = currency;
    }
    const result = await _pay_one_time_for_request_api(postData);
    if (result.code === 200) {
      if (postData.payment_method == "stripe") {
        handleSecureCard(result?.payment, cardElement);
      } else {
        setIsLoading(false);
        window.open(result?.payment);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const pay_now_for_subscription = async (cardElement, token) => {
    setIsLoading(true);
    let postData = {
      payment_method: "stripe",
      request_id: request_id,
      source_token: token,
    };
    const result = await _subcription_by_stripe_api(postData);
    if (result.code === 200) {
      navigate(`/thanks/${params.id}`);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else if (result.code === 210) {
      handleCard(result?.payment, cardElement);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setIsLoading(true);
    let type = result.request_type == "recurring";
    let cardElement = null;
    if (selectedValue) {
      cardElement = elements.getElement(CardElement);
      stripe.createToken(cardElement).then(function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        } else {
          if (type) {
            pay_now_for_subscription(cardElement, result.token?.id);
          } else {
            pay_one_time_for_request(cardElement);
          }
        }
      });
    } else {
      pay_one_time_for_request(cardElement);
    }
  };

  useEffect(() => {
    if (Array.isArray(result?.payment_methods)) {
      if (result?.payment_methods?.includes("stripe")) {
        setSelectedValue(true);
      } else {
        setSelectedValue(false);
      }
    }
    if (result?.request_type == "recurring") {
      if (result?.payment_status == "pending") {
        setAmount(result?.initial_amount);
      } else {
        setAmount(result?.installment_amount);
      }
    } else {
      setAmount(result?.total_amount);
    }
    setCurrency(result?.currency);
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center p-4">
            <img src={s3BaseUrl + gerenal_settings?.admin_logo} alt="" />
          </div>
          <div className="col-md-9 card px-3 mx-auto mb-4">
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <h4 className="text_primary">Invoice Details</h4>
                <div className="bg_light p-3 rounded invoice_detail mt-3">
                  <div className="row">
                    <div className="col-12 ">
                      <h4 className="mb-2 text_primary fs-22">
                        {result?.request_title}
                      </h4>
                    </div>
                    <div className="col-md-6 text-muted mt-2 fs-14">
                      <span className="fw-bold">Invoice No: </span>#20355
                    </div>
                    <div className="col-md-6 text-muted mt-2 fs-14">
                      <span className="fw-bold">Date: </span>{" "}
                      {moment(result?.createdAt).format("DD-MM-YYYY")}
                    </div>
                    <div className="col-md-6 text-muted mt-2 fs-14">
                      <span className="fw-bold">Name: </span>
                      {result?.client_info?.first_name +
                        " " +
                        result?.client_info?.last_name}
                    </div>
                    <div className="col-md-6 text-muted mt-2 fs-14">
                      <span className="fw-bold">Email: </span>
                      {result?.client_info?.email}
                    </div>
                    <div className="col-md-6 text-muted mt-2 fs-14">
                      <span className="fw-bold">Payment Type: </span>
                      {show_proper_words(result?.request_type)}
                    </div>
                    {result?.request_type !== "recurring" && (
                      <div className="col-md-6 text-muted mt-2 fs-14">
                        <span className="fw-bold">Total Amount: </span>
                        {changeCurrency ? (
                          <CircularProgress size="1rem" color="primary" />
                        ) : (
                          `${convertCurrencyToSign(currency) + amount}`
                        )}
                      </div>
                    )}
                    {result?.request_type == "recurring" && (
                      <>
                        <div className="col-md-6 text-muted mt-2 fs-14">
                          <span className="fw-bold">
                            Initial Deposit Amount:{" "}
                          </span>
                          {convertCurrencyToSign(result?.currency) +
                            (result?.initial_amount ?? 0)}
                        </div>
                        <div className="col-md-6 text-muted mt-2 fs-14">
                          <span className="fw-bold">
                            Monthly Installments:{" "}
                          </span>
                          {convertCurrencyToSign(result?.currency) +
                            (result?.installment_amount ?? 0)}
                        </div>
                      </>
                    )}
                  </div>
                  {result?.request_description && (
                    <>
                      <div className="  text-muted mt-4 fs-14">
                        <span className="fw-bold">Invoice Note: </span>
                        {result?.request_description}
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <h4 className="pb-3 pt-3 text_primary">Payment Method</h4>
                  {result?.payment_methods?.includes("stripe") && (
                    <div className="col-12 col-md-6 mb-2">
                      <div
                        className={`card rounded pointer px-3 ${
                          selectedValue ? "card_selected" : "card_select"
                        }`}
                        onClick={() => {
                          handleChangeCheck("stripe");
                        }}
                      >
                        <FormControlLabel
                          value="stripe"
                          control={<Radio />}
                          label="Credit Card"
                          checked={selectedValue}
                        />
                      </div>
                    </div>
                  )}
                  {result?.payment_methods?.includes("fire") &&
                    result?.request_type !== "recurring" && (
                      <div className="col-12 col-md-6 mb-2">
                        <div
                          className={`card rounded pointer px-3 ${
                            selectedValue ? "card_select" : "card_selected"
                          }`}
                          onClick={handleChangeCheck}
                        >
                          <FormControlLabel
                            value="fire"
                            control={<Radio />}
                            label="Bank"
                            checked={!selectedValue}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {!selectedValue && result?.request_type !== "recurring" && (
                  <div className="col-12 mt-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Choose Currency
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="currency"
                        value={currency}
                        label=" Choose Currency"
                        onChange={handleChangeCurrency}
                      >
                        <MenuItem value="GBP">UK Pounds</MenuItem>
                        <MenuItem value="EUR">Euro</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                {selectedValue && (
                  <div className="mt-3">
                    {errorMessage && (
                      <div
                        className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                        role="alert"
                      >
                        {errorMessage}
                        <div onClick={handleAlertClose}>
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    )}
                    <h5 className="fs-16 text-black">Enter Card Details</h5>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            iconColor: "#438a7a",
                            color: "#000",
                            "::placeholder": {
                              color: "#000000a6",
                            },
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mt-2 pb-1">
                    <span className=" text-black">Total Amount </span>
                    <h6 className="mb-0 text-black">
                      {changeCurrency ? (
                        <CircularProgress size="1rem" color="primary" />
                      ) : (
                        `${convertCurrencyToSign(currency) + amount}`
                      )}
                    </h6>
                  </div>
                  <div className="mt-3">
                    <button
                      className="w-100 btn btn-primary"
                      disabled={changeCurrency || isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay Now"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
