import React, { useEffect, useState } from "react";
import { Toolbar, Drawer, Divider, Box, Tooltip } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavSection from "./NavSection";
import { useAdminContext } from "../../Hooks/AdminContext";
import { s3BaseUrl } from "../../config/config";

const drawerWidth = 260;

function AppSideBar({ mobileOpen, handleDrawerToggle }) {
  const { adminSettingList, navItemsList } = useAdminContext();
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function handleScroll() {
    const isScrolled =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > window.innerHeight;
    setShowScrollArrow(isScrolled);
  }

  let default_paths = ["/edit-profile"];

  const checkIfPathExists = (navItems, currentPath) => {
    for (const path of default_paths) {
      if (currentPath.includes(path)) {
        return true;
      }
    }
    for (const navItem of navItems) {
      if (navItem.path && currentPath.includes(navItem.path)) {
        return true;
      }
      if (navItem.matches && navItem.matches.includes(currentPath)) {
        return true;
      }
      if (navItem.child_options) {
        for (const childOption of navItem.child_options) {
          if (
            childOption.path &&
            (currentPath.includes(childOption.path) ||
              childOption.path.includes(currentPath))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handlePathname = () => {
    if (pathname) {
      const pathParts = pathname.split("/");
      if (pathParts.length > 2) {
        return pathParts.slice(0, 2).join("/");
      } else {
        return pathname;
      }
    }
  };

  const drawer = (
    <>
      <div className="text-center p-2">
        <img
          src={s3BaseUrl + adminSettingList?.admin_logo}
          className="img-fluid "
        />
      </div>
      <Divider />
      <NavSection navConfig={navItemsList} />
    </>
  );

  useEffect(() => {
    const isPathInNavItems = checkIfPathExists(navItemsList, handlePathname());
    if (!isPathInNavItems) {
      navigate("/dashboard");
    }
  }, [pathname, navItemsList]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingBottom: {
            md: "100px",
          },
          minHeight: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Outlet />
        <div
          className="scroll-top-community"
          style={{
            bottom: showScrollArrow ? "50px" : "-80px",
          }}
          onClick={handleScrollTop}
        >
          <Tooltip title="Scroll to top">
            <div>
              <i class="fa-solid fa-arrow-up"></i>
            </div>
          </Tooltip>
        </div>
      </Box>
      <footer className="footer mt-3 mt-md-5 d-none d-md-block">
        <div className="d-block d-md-flex text-center justify-content-md-between align-items-center ">
          <div>{adminSettingList?.brand_name}</div>
          <div className="text-center text-md-end mt-3 mt-md-0">
            {adminSettingList?.copyright_text}
          </div>
        </div>
      </footer>
    </>
  );
}

export default AppSideBar;
