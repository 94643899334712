import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import moment from "moment/moment";

const ResolvedTicket = ({ handleClickDetail, ticketList }) => {
  const [dataList, setDataList] = useState([]);
  const get_ticket_list = async () => {
    if (ticketList.length > 0) {
      let data = ticketList.map((item) => {
        let created_at = "N/A";
        let resolve_at = "N/A";
        if (item.support_ticket_date) {
          created_at = moment(item.support_ticket_date).fromNow();
        }
        if (item.last_action_date) {
          resolve_at = moment(item.last_action_date).fromNow();
        }
        return {
          ...item,
          created_at,
          resolve_at,
        };
      });
      setDataList(data);
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "subject",
      label: "Subject",
      renderData: (row) => {
        return (
          <>
            <span
              className="pointer"
              onClick={() => {
                handleClickDetail(row);
              }}
            >
              {row.subject}
            </span>
          </>
        );
      },
    },
    {
      id: "department",
      label: "Department",
      renderData: (row) => {
        return (
          <>
            <span>{row.department ? row.department.name : "N/A"}</span>
          </>
        );
      },
    },
    { id: "created_at", label: "Created" },
    { id: "resolve_at", label: "Resolved" },
  ];

  useEffect(() => {
    get_ticket_list();
  }, [ticketList]);

  return (
    <>
      <CustomTable
        data={dataList}
        TABLE_HEAD={TABLE_HEAD}
        is_hide={true}
        pagePagination={false}
      />
    </>
  );
};

export default ResolvedTicket;
